import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
//import Logo from "../images/404/logo.png"
import Logo1 from "../images/404/logo1.png"
import Logo2 from "../images/404/logo2.png"

/**
 *
 * check if user looged in. if not then rember destination and redirect to hopme page where they can do login
 * in login- if there is destination then redirect to it
 *
 */
const NotFoundPage = (props) => (

  <div className="container-fluid main-404">
    <SEO title="404: Not found" />
    <main>


      <div className="row">
        <div className="col pic-left" ></div>
        <div className="col">

          <div className="container-fluid">

            <div className="row">
              <div className="col text-center">
                {/* <a href="/"><img src={Logo} alt="logo" /></a> */}
                <a href="/"><img src={Logo1} alt="logo" /></a>
                <a href="/"><img src={Logo2} alt="logo" className="ms-2" /></a>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="Ooops ff-mont-black">Ooops!</div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="text1">
                  it looks like this page or this file you<br /> are looking for doesn’t exist.
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="text2 my-4">
                  Maybe go to the advanced search page<br />
                  and try to refine your search?


                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="btn-search">
                  <a className="btn" href="/" >Refine your search</a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="response-code ff-mont-bold">404</div>

              </div>
            </div>

          </div>
        </div>
        <div className="col pic-right"></div>

      </div>


    </main>


  </div>

)


export const NotFoundAnon = (props) => {

  //navigate('/')
  //console.log(props)

  const { location } = props

  const destination = location.pathname + location.search
  if (destination !== '/') {
    //console.log('change destination')
    localStorage.setItem('destination', destination)
  }

  return (
    <>
      <Helmet>
        <meta http-equiv="refresh" content="0;URL='/'" />
      </Helmet>
      {/* <NotFoundPage /> */}
    </>
  )
}

export default NotFoundPage
