import React from "react"
import { Link } from "@reach/router"
//import Layout from "../components/layout/layout"
import SEO from "../components/seo"
//import /* Logo, */ { LogoMobile, LogoMobileSmall } from "../components/layout/logo"

//import Img1 from '../images/registration/success-desktop.png'
import Img2 from '../images/registration/success-mobile.png'
import Logo from '../images/registration/logo.png'

const RegisterSuccessPage = (props) => (

  <>
    <SEO title="Register Success" />
    <div className="show-desktop">
      <div className="registration-success">

        <div className="center-screen" >
          <div className="bg-image" >

            <div className="text">
              <img src={Logo} alt="" />
              <h2>Success</h2>

              <h3>Thank you for registering.</h3>
              <p className="px-4">
                Your application for an account is currently pending approval.
                Once it has been approved, you will receive an e-mail containing information about how to log in{/* , set your password, and other details */}.
              </p>
              <Link className="btn mt-2" to="/">Home</Link>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div className="show-mobile">
      <div className="registration-success">

        <div className="center-screen" >
          <div className="" >

            <div className="mobile-text">
              <img src={Logo} alt="" />
              <h2>Success</h2>

              <h3>Thank you for registering.</h3>
              <img src={Img2} alt="" className="mb-3" />
              <p>
                Your application for an account is currently pending approval.
                Once it has been approved, you will receive an e-mail containing information about how to log in{/* , set your password, and other details */}.
              </p>
              <Link className="btn " to="/">Home</Link>
            </div>

          </div>
        </div>
      </div>
    </div>
  </>
)

/*

const RegistrationsSuccess = () => (

)
 */


export default RegisterSuccessPage