import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import FilesTable from "../components/list/files-table"
import FileDetails from '../components/file/details'
import { useSelector } from 'react-redux'
//import state from "../state"

const FavoritesPage = (props) => {

  const { location } = props

  return (

    <Layout location={location} title="Favorites">
      <SEO title="Favorites" />

      <div className="container-fluid container-files g-0" >
        <div className="row g-0">
          <div className="col">
            <TheFiles location={location} />
          </div>
        </div>
      </div>

    </Layout>

  )
}


const TheFiles = (props) => {

  const data = useSelector(state => ({ ...state.app }))
  //console.log( data.files )


  if (!data.files) return false;

  if (data.files.length < 1) return false;

  const files = data.files
    .filter(f => f.favorite)
    .map(f => ({ ...f, to: '/favorites/?id=' + f.id }))

  return (
    <>
      <div className="show-mobile">
        <div className="container-fluid"  >
          <FilesTable files={files} location={props.location} key={props.location.search} />
          <FileDetails location={props.location} key={'2' + props.location.search} />
        </div>
      </div>
      <div className="show-desktop">
        <div className="container-fluid" style={{ padding: "40px" }} >
          <FilesTable files={files} location={props.location} key={props.location.search} />
          <FileDetails location={props.location} key={'2' + props.location.search} />
        </div>
      </div>
    </>
  )
}



export default FavoritesPage
