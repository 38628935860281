import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "@reach/router"

import ArrowBack from "../../images/arrow-back.svg"
import ArrowForward from "../../images/arrow-forward.svg"
import './slider.scss';

export class Slider extends React.Component {


  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {

    const { mobile = false } = this.props

    //console.log('mobile=', mobile)


    const container = this.ref.current
    const all = container.getElementsByClassName('category-level-1-active')
    if (all.length > 0) {

      const selected = all[0]

      const box0 = container.getBoundingClientRect()
      const box = selected.getBoundingClientRect()

      if (mobile) {
        if (box.left > box0.left) {
          this.ref.current.scroll({
            left: box.left,
            top: 0,
            behavior: 'instant'
          })
        }
        else {
          /* this.ref.current.scroll({
            left: 0,
            top: 0,
            behavior: 'smooth'
          }) */
        }
      }

      if (!mobile) {
        if (box.right > box0.right) {
          this.ref.current.scroll({
            left: box.right,
            top: 0,
            behavior: 'instant'
          })
        }
        else {
          /* this.ref.current.scroll({
            left: 0,
            top: 0,
            behavior: 'smooth'
          }) */
        }
      }
    }

  }

  onMouseDown = e => {
    this.setState({
      ...this.state, isScrolling: true,
      clientX: e.clientX
    });
  };

  onMouseUp = () => {
    this.setState({ ...this.state, isScrolling: false });
  };

  onMouseMove = e => {
    const { clientX, scrollX } = this.state;

    if (this.state.isScrolling) {
      //console.log( this.ref.current , clientX, scrollX )
      //console.log( e.clientX )
      //console.log(this.ref.current)
      //console.log(this.ref.current.scrollLeft)
      this.ref.current.scrollLeft = scrollX + e.clientX - clientX;
      //console.log(this.ref.current.scrollLeft)
      this.setState({
        scrollX: scrollX + e.clientX - clientX,
        clientX: e.clientX
      })
      //this.state.scrollX = scrollX + e.clientX - clientX;
      //this.state.clientX = e.clientX;
    }
  };

  prev = () => {
    this.ref.current.scroll({
      left: this.ref.current.scrollLeft - 260,
      top: 0,
      behavior: 'smooth'
    })

  }

  next = () => {
    this.ref.current.scroll({
      left: this.ref.current.scrollLeft + 260,
      top: 0,
      behavior: 'smooth'
    })

  }

  render() {
    const { terms, corporate } = this.props;


    const items = terms
      .filter(term => (corporate ? term.filesNumber !== 0 : true))
      .map((term, index) => {

        const classes = ['category-level-1', 'slide']
        if (terms.length === index + 1) {
          classes.push('category-level-1-last')
        }
        if (term.active)
          classes.push('category-level-1-active')

        if (term.filesNumber === 0)
          classes.push('category-level-1-disabled')


        return (
          <div key={term.id} className={classes.join(' ')} >
            <div className="wraper">
              {term.filesNumber > 0 && <Link to={'/files/?' + term.link}>{term.label}
                <span className="filesNumber">{term.filesNumber} files</span>
              </Link>
              }

              {term.filesNumber === 0 && <div>{term.label}
                <span className="filesNumber">{term.filesNumber} files</span>
              </div>
              }


            </div>
          </div>
        )
      })

    // if (corporate) {

    //   return (
    //     <div className="slider slider-corporate">
    //       <div
    //         ref={this.ref}
    //         //onMouseDown={this.onMouseDown}
    //         //onMouseUp={this.onMouseUp}
    //         //onMouseMove={this.onMouseMove}
    //         className="slides px-0 mx-0"
    //       >
    //         {items}
    //       </div>

    //     </div>
    //   );
    // }

    return (
      <div className={"slider " + ( corporate  ? ' slider-corporate' : '')}>
        <div>
          <button onClick={this.prev} >
            <img src={ArrowBack} alt="back" />
          </button>
        </div>

        { !corporate && <div className="left-grad"></div> }

        <div
          ref={this.ref}
          className="slides"
        >
          {items}
        </div>

        { !corporate && <div className="right-grad"></div> }

        <div>
          <button onClick={this.next} >
            <img src={ArrowForward} alt="forward" />
          </button>
        </div>
      </div>
    );
  }
}

Slider.defaultProps = {
  ref: { current: {} },
  corporate: false,
  //rootClass: '',
};

Slider.propTypes = {
  ref: PropTypes.object,
  corporate: PropTypes.bool,
  //rootClass: PropTypes.string,
  //children: PropTypes.string,
};

export default Slider;