import React from "react"
import Sidebar from "./sidebar"
import "./sidebar.scss"
import { Navbar, Container } from "react-bootstrap"

const Bar = ({ location }) => {


  return (<Navbar bg="light" expand="lg">
    <Container>
      {/* <Navbar.Brand href="/"> Alma Partners</Navbar.Brand> */}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Sidebar location={location} />

      </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}

export default Bar