import React from "react"
import { Link } from "@reach/router"
import Logo0 from "../../images/logo-new.svg";
import LogoLogin from "../../images/logo-login.svg";
import LogoDark from "../../images/logo-dark.png"
import Logo1 from "../../images/logo-mobile.png"

import Logo1a from "../../images/logo/logo1.png"
import Logo2a from "../../images/logo/logo2.png"


import "./logo.scss"

const Logo = () => {
  return (
    <Link to="/" className="logo">
      <img src={LogoLogin} alt="Alma Partners Zone" />
      {/* <div className="word-partner text-uppercase">Partner</div>
      <div className="word-zone text-uppercase">Zone</div> */}
    </Link>
  )
}

const LogoSidebar = () => {
  return (
    <Link to="/" className="logo">
      <img src={Logo0} alt="Alma Partners Zone" />
      {/* <div className="word-partner text-uppercase">Partner</div>
      <div className="word-zone text-uppercase">Zone</div> */}
    </Link>
  )
}

const LogoMobile = () => {
  return (
    <Link to="/" className="logo-mobile">
      <img src={Logo1} alt="Alma Partners Zone" />
      <div className="word-partner">Partners</div>
      <div className="word-zone ps-1">Zone</div>
    </Link>
  )
}

const LogoMobileSmall = () => {
  return (
    <Link to="/" className="logo-mobile">
      {/* <img src={Logo1} alt="Alma Partners Zone" /> */}
      <div className="word-partner">Partners</div>
      <div className="word-zone ps-1">Zone</div>
    </Link>
  )
}

const LogoWide = () => {



  return (
    <Link to={'/'} className="logo-dark">
      <img src={LogoDark} alt="Alma Partners Zone" />
      <span></span>
      <div className="word-partner">Partner</div>  <div className="word-zone">Zone</div>
    </Link>
  )

}

const LogoWide2 = () => {



  return (
    <Link to={'/'} className="logo-dark text-center">
      <a href="/"><img src={Logo1a} alt="logo" /></a>
      <a href="/"><img src={Logo2a} alt="logo" className="ms-2" /></a>
    </Link>

  )

}


export default Logo
export { LogoWide, LogoMobile, LogoMobileSmall, LogoWide2, LogoSidebar }