

const getOptions = (props) => {


  const terms = props.terms
  const products0 = props.products
  const categories0 = props.categories

  //console.log(categories0);


  const categories1 = categories0
    .filter(c => c.parent === '0')
    .sort((a, b) => a.weight > b.weight ? 1 : -1)
    .map(t => ({
      ...t,
      sub: categories0
        .filter(t2 => t2.parent === t.id)
        .sort((a, b) => a.weight > b.weight ? 1 : -1)
    }))


  const categories2 = []
  categories1.forEach(e => {
    const subs = e.sub
    delete e.sub
    categories2.push(e)
    subs.forEach(s => {
      categories2.push(s)
    })
  })

  //console.log(categories1)
  //console.log(categories2)

  //console.log( terms2 )

  if (terms.length < 1) return false;

  //const terms = terms2.map( t => ({...t , selected: false }))


  const treatments = terms
    .filter(t => t.vid === 'treatments')
    .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
    .map(t => ({ ...t, selected: false }))

  const products = products0.map(t => ({ ...t, selected: false }))
  /*
    const map =  categories0
    .filter(c => c.parent == 0)
    .map()  */

  const categories = categories2
    .map(t => ({ ...t, selected: false }))


  //console.log( categories )

  //const file_types = this.context.terms.filter(t => t.vid === 'file_types')

  const extensions = props.files.map(f => {

    //console.log( f.files )

    //const files = f.additional_files ?  [f.file, ...f.additional_files] : [f.file]
    const ex = f.files
      //.map(ff=>ff.filemime) // filename
      .map(ff => ff.file.filename.split('.').pop().toLowerCase()) // filename
      .filter((v, i, a) => a.indexOf(v) === i)
      .reduce((elem1, elem2) => elem1.concat(elem2), [])

    return ex
  })
    .reduce((elem1, elem2) => elem1.concat(elem2), [])
    .filter((v, i, a) => a.indexOf(v) === i)
    .map(e => ({
      id: e,
      label: e,
      selected: false,

    }))

  //console.log( extensions)


  //console.log( langs)

  //additional_files: (2) [{…}, {…}]
  //additional_languages: [{…}]
  //file
  //language

  /*
  "pdf"    - doc
  1: "zip" - archive
  2: "mp4" - movie
  3: "wmv" - movie
  4: "mov" - movie
  5: "jpg" - image
  6: "rar"- archive
  7: "png"- image
  8: "pptx"-presentaion
  9: "eps"
  10: "bin"
  11: "gif" - image
  12: "apk"
  13: "docx" - doc
  14: "psd"
  15: "ppt"
  16: "m4v"
  17: "ai"
  18: "bmp"
  19: "doc" - doc
  20: "avi"
  21: "tif"
  22: "html" - doc
  23: "xlsx" - spread sheet  */


  const langs = props.search.langs.map(t => ({
    id: t,
    label: t,
    selected: false
  }))


  const options = {
    products: products,
    categories: categories,
    treatments: treatments,
    langs: langs,
    extensions: extensions,
  }

  return options
}


const getParams = (search) => {
  const searchParams = new URLSearchParams(search);

  const keys = ['sections', 'products', 'categories', 'treatments', 'langs', 'extensions'];
  const params = {
    sections: searchParams.get('sections') || '',
    products: searchParams.get('products') || '',
    categories: searchParams.get('categories') || '',
    treatments: searchParams.get('treatments') || '',
    keywords: searchParams.get('keywords') || '',
    year: searchParams.get('year') || '',
    langs: searchParams.get('langs') || '',
    extensions: searchParams.get('extensions') || '',
  }

  keys.forEach(k => {
    if (params[k]) {
      params[k] = params[k].split(',')
    }
  });

  return params;
}


const buildSearchQuery = (params) => {

  const { keywords, products, treatments, categories } = params
  const { langs, extensions } = params

  const searchParams = new URLSearchParams();


  if (keywords !== '') {
    searchParams.set('keywords', keywords)
  }

  if (products.length > 0) {
    searchParams.set('products', products.join(','))
  }

  if (treatments.length > 0) {
    searchParams.set('treatments', treatments.join(','))
  }

  if (categories.length > 0) {
    searchParams.set('categories', categories.join(','))
  }

  if (langs.length > 0) {
    searchParams.set('langs', langs.join(','))
  }

  if (extensions.length > 0) {
    searchParams.set('extensions', extensions.join(','))
  }

  return searchParams.toString()
}


export { getOptions, getParams, buildSearchQuery }