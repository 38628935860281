import React from "react"
import Layout2 from "../components/layout/layout2"
import SEO from "../components/seo"
import PasswordRecoveryForm from "../components/user/password-recovery"
import ForgetPassword from "../components/user/forget"

const IndexPage = (props) => (
  <Layout2
    location={props.location}
    side1={<ForgetPassword />}
    side2={<Recovery />}
    className="reverse"
  >
    <SEO title="Password" />

  </Layout2>
)

const Recovery = () => {

  return (
    <div className="recovery-block text-start">

      <h1 className="ff-mont-bold">No Problem.</h1>
      <h1 className="">Type in your email address: </h1>
      <div className="content">
        <p className="my-4">
          The Alma Partners Zone is dedicated to owners and distributors of Alma Lasers products.
        </p>

        <PasswordRecoveryForm />
      </div>
    </div>
  )
}


export default IndexPage
