import React from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux'
import { Link } from "@reach/router"
import FirfoxMessage from "./firefox";

class Login2Form extends React.Component {

  validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    }
    /* else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    } */


    if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  }

  submit = (values, { setSubmitting }) => {

    const payload = {
      username: values.email,
      password: values.password
    }

    this.props.dispatch({ type: 'user/login', payload: payload })

  }

  render() {

    const { messages } = this.props

    const msgs = messages.map((m, i) => (
      <div className="alert alert-danger" key={i} role="alert">{m}</div>
    ))

    const initialValues = {
      email: '',
      password: '',
    }

    return (
      <div>

        <Formik
          initialValues={initialValues}
          validate={this.validate}
          onSubmit={this.submit}
        >
          {({
            isSubmitting,
            isValid
            /* and other goodies */
          }) => (
            <div className="login-form">
              <Form>
                <div className="container">

                  <div className="row">
                    <div className="col">
                      {msgs}

                      {/* submitting: { isSubmitting ? 'true' : 'false' } */}
                    </div>
                  </div>

                  <div className="row">

                    <div className="col">
                      <InputField name="email" title="E-mail" type="text" required={true}></InputField>
                      <InputField name="password" title="Password" type="password" required={true}></InputField>

                      <Link to="/password-recovery" className="forgot-pass">Forgot your password?</Link>
                    </div>

                  </div>


                  <div className="row">
                    <div className="col">
                      <button type="submit" className="btn btn-primary btn-wide" disabled={!isValid && !isSubmitting} /* disabled={isSubmitting} */>
                        Log in
                      </button>

                    </div>
                  </div>


                  <div className="row">
                    <div className="col">
                      <br />
                      {' '}
                      <FirfoxMessage />
                    </div>
                  </div>

                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    )

  }
}

const InputField = ({ name, title, type = 'text', required = false, children }) => {

  return (
    <div className="_container">
      <div className="_row mb-3">

        <div className="_col">
          <label htmlFor={name + "Input"} className="form-label">{title} {required ? ' *' : ''}</label>
          <Field type={type} name={name} className="form-control" id={name + "Input"} required={required} />
          <ErrorMessage name={name} component="div" className="alert alert-danger" />
          {children}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { user } = state
  return { ...user }
}

const LoginForm = connect(mapStateToProps)(Login2Form)

export default LoginForm