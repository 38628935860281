import React from "react"
import { useSelector } from 'react-redux'
import Card from './card'
import {  Link  } from "@reach/router"
import "./block3.scss"

const WebinarsBlock = () => {

  const webinars = useSelector(state => state.app.webinars.slice(0,5) )


  const items = webinars.map(w => (
    <div className="webinar" key={w.id}>
      <Card {...w}  withDate={false} />
    </div>
  ))

  return (
    <div className="block block-webinars">
      <div className="block-header">
      <h3 className="title">Latest webinars</h3>
      <Link to='/webinars'>View all webinars</Link>
      </div>

      <div className="container-webinars">
        {items}
      </div>

    </div>

  )
}

export default WebinarsBlock