import React  from "react"
import { useSelector } from 'react-redux'
import "./in-the-media.scss"
import LinesEllipsis from 'react-lines-ellipsis'

const InTheMedia = () => {

  const events = useSelector(state => (state.app.media))

  const cards = events.slice(0,8).map((w,i) => {
    return (
      <CardInTheMedia {...w} key={i} />
    )
  })

  return (
    <div className="block block-in-the-media">

      <div className="block-header">
        <h3>Blog Articles</h3>
      </div>

      <div className="block-body">
        <div className="container mx-0 px-0">
          <div className="row mx-0 px-0">
            {cards}
          </div>
        </div>

      </div>

    </div>
  )
}


const  CardInTheMedia = (props) => {


  const {title, body, event_link,  image  } = props;
  const text = body  ?  body.replace(/(<([^>]+)>)/gi, "").replace('&nbsp;',' ') : '';
  return (
    <div className="col-md-6">
      <div className="card card-in-the-media">

      <a href={event_link} target="__blank">
          <img src={image.url} alt={title} title={title} width="174" height="113" />
          </a>


          <div className="my-3">
            <a href={event_link} title={title} target="__blank" >
            <LinesEllipsis
              text={text}
              maxLine='3'
              ellipsis='...'
              trimRight
              basedOn='words'
            />
            </a>

          </div>

      </div>
    </div>
  )

}


export default InTheMedia
