import React from "react"
import SEO from "../components/seo"
import PasswordResetForm from "../components/user/password-reset"
import Layout2 from "../components/layout/layout2"
import ForgetPassword from "../components/user/forget"


const IndexPage = (props) => (

  <Layout2
    location={props.location}
    side1={<ForgetPassword />}
    side2={<ResetBlock />}
    className="reverse"
    >
    <SEO title="Password Reset" />

  </Layout2>

)


const ResetBlock = () => {

  return (
    <div className="password-reset-block">
      <h1>Choose a new password</h1>
      <p>Check your email and type the verification code.</p>
      <PasswordResetForm />
    </div>
  )

}

export default IndexPage
