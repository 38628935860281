import React, { useState } from "react"
import Img from '../../images/tutorial.png'
import ImgLight from '../../images/tutorial-light.png'
import TutorialModal from "./tutorial-modal"

//tutorial

const TutorialButton = () => {

  const initShow = () => {
    let item = localStorage.getItem('Tutorial')
    if (item === null) {
      localStorage.setItem('Tutorial', false)
      item = 'false'
    }

    return item !== 'true'
  }

  const [show, setShow] = useState(initShow())

  const click = () => {
    setShow(true)
  }

  const close = () => {
    localStorage.setItem('Tutorial', true)
    setShow(false)
  }

  return (
    <>
      <button className="btn btn-tutorial" onClick={click} >
        <div className="content">

          <img src={Img} alt="Watch Tutorial" height="19" width="25" />
          <span><i>Watch Tutorial</i></span>
        </div>
      </button>

      <TutorialModal show={show} onClose={close} />

    </>
  )

}

const TutorialLink = () => {

  const initShow = () => {
    let item = localStorage.getItem('Tutorial')
    if (item === null) {
      localStorage.setItem('Tutorial', false)
      item = 'false'
    }

    return item !== 'true'
  }

  const [show, setShow] = useState(initShow())

  const click = () => {
    setShow(true)
  }

  const close = () => {
    localStorage.setItem('Tutorial', true)
    setShow(false)
  }

  return (
    <>
      <button className="btn btn-tutorial" onClick={click} >
        <img src={ImgLight} alt="Watch Tutorial" height="19" width="25" />
        <span>Watch Tutorial</span>
      </button>
      <TutorialModal show={show} onClose={close} />
    </>
  )

}

export { TutorialLink }
export default TutorialButton