const initialState = {
  paths: [],
  search: [],
};

const Reducer = (state = initialState, action) => {

  switch (action.type) {
    case 'stats/view-sccuess':
      return { ...state, paths: [...state.paths, action.payload.url] };

    case 'stats/search':
      return { ...state, search: [...state.search, action.payload.key] };

    case 'app/load':
      return { ...state, paths: [], search: [] };

    default:
      return state;
  }
};

export default Reducer