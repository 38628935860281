import { createStore, applyMiddleware  } from 'redux';
import rootReducer from './index';
import {loadState, saveState} from './localStorage';
import {auth} from './middleware/auth';
import {data} from './middleware/data';
import {masquerade} from './middleware/masquerade';
import {stats} from './middleware/stats';
import {notification} from './middleware/notification';
import {recovery} from './middleware/recovery';
import {registration} from './middleware/registration';


const persistedState = loadState();

//console.log(persistedState)

const middlewareEnhancer = applyMiddleware(auth, data, masquerade, stats, notification, recovery, registration)

const store = createStore(rootReducer , persistedState,  middlewareEnhancer );

store.subscribe(() => {
  saveState( store.getState() );
});

//store.subscribe(() => console.log(store.getState()))


export default store;