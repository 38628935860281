import React from "react"
import {  Link, navigate  } from "@reach/router"
import { useSelector, useDispatch } from 'react-redux'

const MasqueradeLinks = () => {

  const {isLoggedIn, user } = useSelector(state => (state.user))
  const masquerade = useSelector(state => (state.masquerade))

  const {old_user } = masquerade

  //console.log( user.roles)
  //console.log( masquerade)

  const dispatch = useDispatch();

  if( ! isLoggedIn )
    return false


  const isMasqueradingAllowed = user.roles.includes('administrator')   ;
  const isMasquerading =  old_user !== false ;

  //console.log( isMasqueradingAllowed, isMasquerading );

  return(
    <>
      { isMasqueradingAllowed && ! isMasquerading &&  <><Link to={'/masquerade'}>masqurade</Link></> }
      { isMasquerading && <><button className="btn" onClick={() => {dispatch({type:'masquerade/unmasquerade', payload:{}}); navigate('/')}}>unmasqurade</button></> }
    </>
  )
}


export default MasqueradeLinks
