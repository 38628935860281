import React/* , {useState} */ from "react"
//import Layout from "../components/layout/layout"
//import SEO from "../components/seo"
import Card from './card'
import { useSelector/* , useDispatch */ } from 'react-redux'
//import "../components/webinar/block.scss"
import Modal from 'react-bootstrap/Modal'
import "./videos.scss"


class Vidoes extends React.Component {

  state = {
    open: false,
    video: null
  }

  click = (video) => {

    const { dispatch } = this.props
    dispatch({
      type: 'video/load',
      payload: {
        uri: video.uri
      },
    })

    this.setState({ open: true, video: video })


  }

  reset = () => {

    const { dispatch } = this.props

    dispatch({
      type: 'video/reset'
    })

    this.setState({ open: false, video: null })
  }

  render() {

    const { videos } = this.props
    const { video, open } = this.state

    const cards = videos.map(item => {

      return (
        <div className="col-md-3" key={item.id}>
          <button onClick={() => { this.click(item) }}>
            <Card {...item} />
          </button>
        </div>
      )

    })



    return (
      <>

        {open && <VideoModal video={video} show={open} onHide={this.reset} />}

        <div className="container-fluid container-app">
          <div className="row row-videos">
            {cards}
          </div>
        </div>

      </>
    )

  }
}

const VideoModal = (props) => {

  //const dispatch = useDispatch()
  const s = useSelector(state => state.app.video)

  //console.log( s )

  const { loading, url } = s
  const { video, onHide, show } = props


  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>{video.description}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div>Loading...</div>}
        {url && <VideoPlayer url={url} />}
      </Modal.Body>
    </Modal>

  )
}

const VideoPlayer = (props) => {

  const { url } = props

  return (
    <video width="100%" h_eight="240" controls>
      <source src={url} />
      Your browser does not support the video tag.
    </video>
  )
}

export default Vidoes
