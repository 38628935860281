import React from "react"
import Layout2 from "../components/layout/layout2"
import SEO from "../components/seo"
import ForgetPassword from "../components/user/forget"
import { navigate } from "@reach/router"

const IndexPage = (props) => (
  <Layout2
    location={props.location}
    side1={<ForgetPassword />}
    side2={<Success />}
    className="reverse"
    >
    <SEO title="Password Recovery Success" />

  </Layout2>
)

const Success = () => {

  return (
    <div className="password-reset-block">
        <h1>Success!</h1>
        <h1>Password has been changed.</h1>
        <p>
          The Alma Partners Zone is dedicated to owners and distributors of Alma Lasers products.
        </p>
        <button
          onClick={() => {navigate('/')  }}
          className="btn btn-wide"
          >to Login</button>
    </div>
  )
}


export default IndexPage
