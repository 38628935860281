import Axios from "axios"
import { endPoint } from "../config"

const stats = storeAPI => next => action => {

  //console.log('stats middleweare', 'before', 'action type:', action.type)


  next(action)

  //console.log('stats middleweare', 'after', 'action type:', action.type)

  if (action.type === 'stats/view') {
    const state = storeAPI.getState()
    const { paths } = state.stats

    if (!paths.includes(action.payload.url)) {
      pushPath(state.user.jwt, storeAPI.dispatch, action.payload)
    }
  }

  if (action.type === 'stats/search') {
    const state = storeAPI.getState()
    pushSearch(state.user.jwt, storeAPI.dispatch, action.payload)
  }

}

const pushSearch = (jwt, dispatch, payload) => {

  //console.log(payload)

  if (payload.host !== 'partners.almalasers.com') {
    console.log('not production - not sending stats')
  }

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.post(
    endPoint + `/api/kgmedia-search-stats?_format=json`,
    { ...payload.params },
    { headers: headers }
  )
    .then(res => {

      dispatch({
        type: 'stats/search-sccuess',
        payload: {},
      })

    })
    .catch(error => {
      dispatch({ type: 'error', payload: { error: error } })
    })

}

const pushPath = (jwt, dispatch, payload) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.post(
    endPoint + `/api/kgmedia-accesslog?_format=json`,
    { path: payload.url },
    { headers: headers }
  )
    .then(res => {

      dispatch({
        type: 'stats/view-sccuess',
        payload: { url: payload.url },
      })

    })
    .catch(error => {
      dispatch({ type: 'error', payload: { error: error } })
    })

}


export { stats }