import React/* , {useState} */ from "react"
import Layout from "../components/layout/layout"
import { useSelector, useDispatch } from 'react-redux'

const LandingPage = (props) => {

  console.log('props:', props)
  const { nodeId } = props

  const params = new URLSearchParams(props.location.search);
  const token = params.get('token');

  const dispatch = useDispatch();

  if (token) {

    const payload = {
      token: token,
    }

    dispatch({ type: 'user/login', payload: payload })

  }


  /* const dispatch = useDispatch();
  const nodes = useSelector(state => state.app.nodes)
    .filter(node => node.type === 'al_content')

  const node = nodes.find(n => n.id === nodeId)

  if (!node) return <div></div>

  const videos = node.videos.map((v, i) => ({
    ...v,
    thumb: node.videos_thumbnails[i]
  }))
 */



  return (
    <Layout location={props.location} title={'landing'}>
      {/* <SEO title={node.title} /> */}
      <div>
        <h1>Loading...</h1>
        <div>Token: {token}</div>
      </div>
    </Layout>
  )
}

export default LandingPage
