import React from "react"
//import Default from './icons/text-plain.png'

import ExtentionIcon from "./extention2"

const files = [
  'f.ai',
  'f.apk',
  'f.avi',
  'f.bin',
  'f.bmp',
  'f.doc',
  'f.docx',
  'f.eps',
  'f.fla',
  'f.flv',
  'f.gif',
  'f.htm',
  'f.html',
  'f.jpeg',
  'f.jpg',
  'f.m4v',
  'f.mov',
  'f.mp3',
  'f.mp4',
  'f.pdf',
  'f.png',
  'f.ppt',
  'f.pptx',
  'f.psd',
  'f.rar',
  'f.rtf',
  'f.swf',
  'f.tif',
  'f.txt',
  'f.wav',
  'f.wmv',
  'f.xls',
  'f.xlsx',
  'f.zip',
]

const IconList = () => {

  return (

    <div className="p-4">
      <h1>IconList</h1>
      {files.map((f, i) => (
        <div key={i}>
          <ExtentionIcon file={{ filename: f }} />
        </div>
      ))}
    </div>

  )
}

export default IconList