import React from "react"


export const LangNameToLangCode = (name) => {
  switch (name) {
    case 'English':
      return 'EN';
    case 'Spanish':
      return 'ES';
    case 'French':
      return 'FR';
    case 'German':
      return 'DE';
    case 'Dutch':
      return 'NE';
    case 'Russian':
      return 'RU';
    case 'Chinese':
      return 'CA';
    case 'Italian':
      return 'IT';
    case 'hebrew':
      return 'עב';
    case 'Japanese':
      return 'JP';
    case 'Korean':
      return 'KR';
    default:
      return 'NA';
  }
}

const Langs = ({ item }) => {

  const langs = [...item.additional_languages];
  langs.unshift({
    file: item.file,
    language: item.language === null ? 'English' : item.language,
  })

  const langs2 = langs.filter(i => i.language && i.file)

  const items = langs2.map((m, index) => (
    <a className='btn btn-circle' key={index} href={m.file.url} target="__blank">{LangNameToLangCode(m.language)}</a>
  ))

  return <div className="language-links">
    {items}
  </div>
}

export default Langs


export const LangsNoLinks = ({ item }) => {

  //if (!item.additional_languages) return false

  const langs = item.additional_languages ? [...item.additional_languages] : [];
  langs.unshift({
    file: item.file || item.link_external,
    language: item.language === null ? 'English' : item.language,
  })

  const langs2 = langs.filter(i => i.language && i.file)
  //console.log(langs2);
  if (langs2.length === 0)
    return false;

  const items = removeDuplicates(langs2, 'language').map((m, index) => (
    <span className='btn btn-circle ff-mont-bold' key={index} >{LangNameToLangCode(m.language)}</span>
  ))

  return <div className="language-chips">
    {items}
  </div>
}

export const LangSingle = ({ language }) => {
  const item = <span className='btn btn-circle'  >{LangNameToLangCode(language)}</span>
  return <span className="language-chips">{item}</span>
}

function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}
