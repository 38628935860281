import React from "react"
import { LogoWide2 } from "../layout/logo"
import ForgetImg from "../../images/forget.png"

const ForgetPassword = () => {

  return (
    <div className="forget-block">
      <div className="logo-wide">
        <LogoWide2 />
      </div>
      <div>
        <img src={ForgetImg} alt="forget" className="decoration" />
      </div>
      <h2>Forgot Password?</h2>
    </div>
  )
}

export default ForgetPassword
