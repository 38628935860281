import React, { useState } from "react"
import Modal from 'react-bootstrap/Modal'
import DownloadButton from '../button/donwload'
import ShareButton from '../button/share'
import { LangSingle } from '../languages'
import DateFormat from '../date-format'
import ExtentionIcon, { ExtentionIconWWW } from "../extentions/extention2"
import { connect, useDispatch, useSelector } from 'react-redux'
import DownalodArrow from "../../images/download-arrow.svg";
import { endPoint } from "../../state/config"
import { ThreeDots, ChevronUp } from "react-bootstrap-icons"


import './modal.scss'

const FileModal = (props) => {

  const { show, close, location } = props

  if (!props.file || !show) return false;

  const { title, description, image, additional_files, additional_languages, file, language, id, link_external = [] } = props.file
  const { thumb = false } = image
  const { url = false } = thumb

  let files = []
  if (file) {
    files.push({
      file: file,
      description: file.description,
      language: language || 'English'
    })
  }

  const f2 = additional_files ? additional_files.map(f => ({ file: f, description: f.description, language: language || 'English' })) : []
  files = files.concat(f2, additional_languages)

  //console.log(additional_languages)
  //console.log(files)

  const cards = files.map((f, i) => (
    <Card3 {...f} key={i} nid={props.file.id} location={location} />
  ))

  const links = link_external.map((f, i) => (
    <CardLink key={'link' + i} {...f} language={language} />
  ))

  //const image = file.image ? <img style={{width:"200px"}} src={file.image.url} /> : ''
  return (
    <Modal
      show={show}
      onHide={close}
      dialogClassName="modal-drawer"
      aria-labelledby="file-details"

    >
      <Modal.Header /* closeButton */>

        <div className="mr-3">
          <ModalImage url={url} title={title} />
        </div>

        <div className="flex-grow-1" style={{ /* flexGrow: 3, */ paddingLeft: "20px" }}>
          <h5 className="mt-0">{title}</h5>
          {description}
          <SmallText {...props.file} />
          <ShareButton />
          <EditLink id={id} />
        </div>

        <div>
          <button type="button" className="close" onClick={close}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>

      </Modal.Header>
      <Modal.Body>
        <div className="show-mobile">
          {
            files.map((f, i) => (
              <CardMobile {...f} key={i} nid={props.file.id} location={location} />
            ))
          }
        </div>

        <div className="show-desktop">
          {cards}
          {links}
        </div>

      </Modal.Body>
    </Modal>

  )
}


class Card2 extends React.Component {



  open = (url) => {
    if (typeof window !== "undefined") {
      window.open(url);
    }
  }


  download = (item) => {

    if (item.uri === false) {
      alert('no file')
      return
    }

    const { location } = this.props

    this.props.dispatch({
      type: 'app/download',
      payload: {
        item: item,
        fid: item.fid,
        nid: this.props.nid,
        url: '',
        path: location.href,
      }
    })

  }


  render() {

    const { language, file, description, nid } = this.props

    return (

      <div className="card-button" onClick={(e) => this.download(file)} >
        <div className="card" >
          <div className="card-body">
            <div>
              {file.filename}{`  `}
              <LangSingle language={language} />{`  `}
              <ExtentionIcon file={file} />
            </div>
            <div>{description}</div>
          </div>

          <div className="card-actions">
            <DownloadButton file={file} nid={nid} label={<><DownloadIcon />{'  '}Download </>} />
          </div>
        </div>
      </div>

    )
  }
}

const Card3 = connect((state) => ({ jwt: state.user.jwt, downloads: state.app.downloads }))(Card2)



const CardMobile = (props) => {

  const { language, file, nid, location } = props
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  /* const open = (url) => {
    if (typeof window !== "undefined") {
      window.open(url);
    }
  } */


  const download = (item) => {

    if (item.uri === false) {
      alert('no file')
      return
    }

    //const { location } = this.props

    dispatch({
      type: 'app/download',
      payload: {
        item: item,
        fid: item.fid,
        nid: nid,
        url: '',
        path: location.href,
      }
    })

  }


  const toggle = () => {
    setShow(!show)
  }

  const classes = show ? ' bg-grey ' : ''

  return (

    <div className={"card-mobile " + classes} >
      <div className="card-body">

        <div className="d-flex justify-content-between">
          <div>
            <div>{file.filename}</div>
            {show &&
              <div >
                <div>{file.description}</div>

                <LangSingle language={language} />{`  `}

                <ExtentionIcon file={file} />
                <div className="card-actions" onClick={(e) => { download(file) }}>
                  <DownloadButton file={file} nid={nid} label={<><DownloadIcon />{'  '}Download </>} />
                </div>
              </div>
            }
          </div>
          <div className="align-self-center">
            <button className="btn p-1 m-0 outline" onClick={toggle}>
              {!show && <ThreeDots />}
              {show && <ChevronUp />}
            </button>

          </div>
        </div>

      </div>


    </div>


  )

}



const CardLink = (props) => {

  const open = (url) => {
    if (typeof window !== "undefined") {
      window.open(url);
    }
  }



  const { uri, title, language } = props

  return (
    <button className="card-button" onClick={(e) => open(uri)}>
      <div className="card" >
        <div className="card-body">
          <div>
            {title}{`  `}
            <LangSingle language={language} />{`  `}
            <ExtentionIconWWW />

          </div>
          <div>{' '}</div>
        </div>

        <div className="card-actions">
          <span className="btn btn-download">

            <DownloadIcon />{'  '}Download
          </span>

        </div>
      </div>
    </button>
  )
  //}
}

const ModalImage = ({ url, title }) => {

  const pageImage = url ? <img src={url} alt={title} w-idth={150} h-eight={150} /> : <div className="placeholder"></div>

  return (
    <div className="modal-image">
      {pageImage}
    </div>
  )

}

const SmallText = (props) => {

  //console.log( props )

  // price_per_package
  // quantity_per_package
  // number_of_pages

  const { part_number, part_number_lang, created } = props

  const parts = [...part_number_lang]

  if (part_number) {
    parts.unshift({
      part_number: part_number,
      language: "English"
    })
  }

  const p0 = parts.map(p => {
    return (
      <React.Fragment key={p.part_number}>
        {p.part_number} {p.language !== 'English' && p.language} {' • '}
      </React.Fragment>
    )
  })

  const more = [
    {
      field: 'price_per_package',
      label: 'Price per package',
    },

    {
      field: 'quantity_per_package',
      label: 'Quantity per package',
    },
    {
      field: 'number_of_pages',
      label: 'Number of pages',
    },

  ]

  const p1 = more.map((item, i) => {
    const v = props[item.field]
    if (!v) return false

    const vv = item.field === 'price_per_package' ? '$' + v + '.00 USD' : v
    return (
      <div key={i}>{item.label} : {vv}</div>
    )
  })

  return (

    <div className="small-text">
      {p0}
      <DateFormat timestamp={created} />
      {p1}
    </div>
  )
}

const DownloadIcon = () => {

  return <img src={DownalodArrow} alt="download" />

}

const EditLink = (props) => {

  const { user } = useSelector(state => (state.user))
  const { roles } = user


  const allow = roles.includes('administrator') || roles.includes('contributor');

  if (!allow) return false

  const { id } = props
  const url = endPoint + '/node/' + id + '/edit';

  return (
    <div className="edit-link w-100">
      <a href={url} target="__blank">Edit in backend</a>
    </div>
  )
}

export default FileModal
