import React/* , {useContext} */ from "react"
import PropTypes from "prop-types"
import { /* useSelector, */ useDispatch } from 'react-redux'
import OffImg from "../../images/off.png";

const LogoutButton = ( {label}) => {

  const dispatch = useDispatch()

  return <button className="btn btn-logout" onClick={ r =>  { dispatch({type:'user/logout'});  } } >
    <img src={OffImg} alt="icon" style={{marginRight:10}} />
    {label}
    </button>
}



LogoutButton.propTypes = {
  label: PropTypes.string,
}

LogoutButton.defaultProps = {
  label: `Logout`,
}

export default LogoutButton
