import React from "react"
//import Default from './icons/text-plain.png'


/**
 * ai
 * apk
 * avi
 * bin
 * bmp
 * doc
 * docx
 * eps
 * fla
 * flv
 * gif
 * htm
 * html
 * jpeg
 * jpg
 * m4v
 * mov
 * mp3
 * mp4
 * pdf
 * png
 * ppt
 * pptx
 * psd
 * rar
 * rtf
 * swf
 * tif
 * txt
 * wav
 * wmv
 * xls
 * xlsx
 * zip
 */

import ai from './svg/ai.svg'
import apk from './svg/apk.svg'
import avi from './svg/avi.svg'
import bin from './svg/bin.svg'
import bmp from './svg/bmp.svg'
import doc from './svg/doc.svg'
import docx from './svg/docx.svg'
import eps from './svg/eps.svg'
import fla from './svg/fla.svg'
import flv from './svg/flv.svg'
import gif from './svg/gif.svg'
import htm from './svg/htm.svg'
import html from './svg/html.svg'
import jpeg from './svg/jpeg.svg'
import jpg from './svg/jpg.svg'
import m4v from './svg/m4v.svg'
import mov from './svg/mov.svg'
import mp3 from './svg/mp3.svg'
import mp4 from './svg/mp4.svg'
import pdf from './svg/pdf.svg'
import png from './svg/png.svg'
import ppt from './svg/ppt.svg'
import pptx from './svg/pptx.svg'
import psd from './svg/psd.svg'
import rar from './svg/rar.svg'
import rtf from './svg/rtf.svg'
import swf from './svg/swf.svg'
import tif from './svg/tif.svg'
import txt from './svg/txt.svg'
import wav from './svg/wav.svg'
import wmv from './svg/wmv.svg'
import xls from './svg/xls.svg'
import xlsx from './svg/xlsx.svg'
import zip from './svg/zip.svg'
import www from './svg/www.svg'



const ExtentionIcon = ({ file }) => {

  const filename = file ? file.filename : '-'
  const exten = filename.split('.').pop().toLowerCase();
  const type = getIcon(exten)
  const icon = type ? type : www

  /**
   * to do - add link
   */
  return (

    <span>
      <img src={icon} alt={filename} title={filename} height="20" />
    </span>

  )
}

export const ExtentionIconWWW = () => {

  const icon = www

  /**
   * to do - add link
   */
  return (

    <span>
      <img src={icon} alt={'extention'} title={'extention'} height="20" />
    </span>

  )
}

const getIcon = (extention) => {


  switch (extention) {
    case 'ai': return ai;
    case 'apk': return apk;
    case 'avi': return avi;
    case 'bin': return bin;
    case 'bmp': return bmp;
    case 'doc': return doc;
    case 'docx': return docx;
    case 'eps': return eps;
    case 'fla': return fla;
    case 'flv': return flv;
    case 'gif': return gif;
    case 'htm': return htm;
    case 'html': return html;
    case 'jpeg': return jpeg;
    case 'jpg': return jpg;
    case 'm4v': return m4v;
    case 'mov': return mov;
    case 'mp3': return mp3;
    case 'mp4': return mp4;
    case 'pdf': return pdf;
    case 'png': return png;
    case 'ppt': return ppt;
    case 'pptx': return pptx;
    case 'psd': return psd;
    case 'rar': return rar;
    case 'rtf': return rtf;
    case 'swf': return swf;
    case 'tif': return tif;
    case 'txt': return txt;
    case 'wav': return wav;
    case 'wmv': return wmv;
    case 'xls': return xls;
    case 'xlsx': return xlsx;
    case 'zip': return zip;


    default:
      return false;
  }
}



export default ExtentionIcon