import { Link } from "@reach/router"
import React from "react"
//import { getParams, toUrlParams } from "../../services/location"
import { useSelector } from 'react-redux'

//import Slider from './slider'
import './categories.scss'

const Corporate = (props) => {

  const { corporateBlock } = useSelector(state => ({ ...state.app }))

  //console.log(data)

  const flat = []
  corporateBlock.forEach(e1 => {
    e1.forEach(e2 => {
      flat.push(e2)
    })
  });


  const items = flat
    .filter((f, i) => i < 5)
    .map((item, index) => {


      return (
        <div key={index} className="">
          <LinkOrAnchor url={item.link}>

            <div className="card border-0 text-center" >
              <img src={item.image.url} height="auto" /* width="263" */ className="card-img-top" alt={item.title} />
              <div className="card-body text-body ">
                <h5 className="card-title ff-mont-bold text-dark  fs-15px">{item.title}</h5>
                <p className="fs-15px">{item.sub_title}</p>
              </div>
            </div>

          </LinkOrAnchor >
        </div>
      )
    })

  return (
    <div className="container-fluid bg-white px-4 pt-4 mt-4 mb-4 rounded rounded-3">
      <div className="row g-0 mx-2 mt-3 mb-3">
        <h3 className="fs-39px ff-mont-bold " style={{ color: '#442c5d' }}>Featured Files</h3>
      </div>
      <div className="show-desktop">
        <div className="grid-5 g-0 mx-2">
          {items}
        </div>
      </div>
      <div className="show-mobile">
        <div className="grid-1 ">
          {items}
        </div>
      </div>
    </div>
  )

}

const LinkOrAnchor = ({ children, url }) => {

  const isExternal = !url.includes('https://partners.almalasers.com/files')
  const internal = url.replace('https://partners.almalasers.com/files', '/files')

  return (
    <>
      {!isExternal && <Link to={internal} className="text-decoration-none">{children}</Link>}
      {isExternal && <a href={url} rel="noreferrer" target="_blank" className="text-decoration-none">{children}</a>}
    </>
  )
}


export default Corporate