import React from "react"
import { Link } from "@reach/router"
import Dropdown from 'react-bootstrap/Dropdown'
//import { Sliders } from 'react-bootstrap-icons';
import { getParams, toUrlParams/* , filterFiles */ } from "../../services/location"
import { useSelector/* , useDispatch */ } from 'react-redux'
import MySliders from "../../images/subcategories-icon.svg"

const Categories = (props) => {
  const data = useSelector(state => ({ ...state.app }))
  return (

    <Terms
      //terms={data.terms}
      categories={data.categories}
      files={data.files}
      {...props}
    />

  )
}

class Terms extends React.Component {

  render() {

    const {/* params */location,  /* terms, */ categories, files } = this.props
    const params = getParams(location.search)
    const { p, section, c, sub } = params;

    const product = p
    const category = c
    //console.log( files )
    //console.log( params )
    const items = files
      .filter(f => product ? f.termsIds.includes(product) : true)
      .filter(f => section ? f.termsIds.includes(section) : true)
      .filter(f => category ? f.termsIds.includes(category) : true)

    //console.log( items )

    const items2 = categories
      .filter(term => term.vid === "categories" && term.parent === category)
      .map(term => {
        const n = items.filter(f => f.termsIds.includes(term.id)).length
        if (n === 0) {
          return <Dropdown.Item key={term.id} as={Span} className="grey" >{term.label} ({n})</Dropdown.Item>;
        }
        return (
          <Dropdown.Item key={term.id} as={Link} to={'/files/?' + toUrlParams(location.search, { sub: term.id, id: '' })} >{term.label} ({n})</Dropdown.Item>
        )
      })

    const term = categories.find(t => t.id === sub)
    const title = term ? term.label : 'filter sub category'

    if (items2.length === 0) return false;

    return (

      <div className="categories-level-2-container">

        <span className="filter-by ff-mont-bold">Filter by:</span>

        <Dropdown >

          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {/* <Sliders size={20} style={{marginRight:"20px", marginLeft:"12px"}}/> */}
            <Icon style={{ marginRight: "14px", marginLeft: "8px" }} />
            {title}
            <span className="sperator" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {items2}
          </Dropdown.Menu>

        </Dropdown>

        {params.p === '207' && 
          <div class="disclaimer-inner">
            <div class="disclaimer-container">
              <div class="disclaimer-text">
                Please note that the use of these marketing materials is subject to regulatory restrictions specific to each territory.  
          </div></div></div>
        }
      </div>

    )

  }
}

const Span = (props) => {
  return <span {...props}>{props.children}</span>
}

const Icon = (props) => {
  return <img src={MySliders} alt="sliders" width="20" height="20" {...props} />
}

export default Categories
