import React/* , { useState } */ from "react"
import Modal from 'react-bootstrap/Modal'
import CloseButton from 'react-bootstrap/CloseButton'
import "./tutorial-modal.scss"

const TutorialModal = (props) => {

  const { show, onClose } = props

  return (

    <Modal show={show} onHide={onClose} dialogClassName="modal-tutorial" ba_ckdrop="static">
      {/* <Modal.Header closeButton>
        <Modal.Title>Advanced Search</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>

        <div className="close-button">
          <div className="label">close</div>
          <CloseButton className="btn-close-modal" onClick={onClose} />
        </div>
        <div className="container-xl text-center">
          <div className="row">
            <div className="col">
              <h3>New Look & Cool Features</h3>
            </div>

            <div className="row">
              <div className="col">
                {/* embed video
                https://vimeo.com/600898097?utm_source=PZ&utm_medium=pop_up&utm_campaign=Walkthrough */}
                {/*
                <iframe src="https://player.vimeo.com/video/600898097?h=e9ca0c1036" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>

 */}
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                  <iframe src="https://player.vimeo.com/video/600898097?h=e9ca0c1036&title=0&byline=0&portrait=0"
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",

                    }}
                    title="Tutorial video"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen>

                  </iframe>
                </div>

                {/*   <script src="https://player.vimeo.com/api/player.js"></script> */}
              </div>
            </div>

            <div className="row">
              <div className="col text-center">
                <p>After months of preps and upgrades, we are proud to introduce the new Alma Partners Zone</p>
              </div>

            </div>
          </div>
        </div>

      </Modal.Body>
    </Modal >

  )

}


export default TutorialModal