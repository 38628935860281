import  firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
	// Project Settings => Add Firebase to your web app


  apiKey: "AIzaSyA-dA13RgVTwZHzKT0nUT7118sBcmhUePM",
  //authDomain: "partners-almalassers.firebaseapp.com",
  //databaseURL: "https://partners-almalassers.firebaseio.com",
  projectId: "partners-almalassers",
  //storageBucket: "partners-almalassers.appspot.com",
  messagingSenderId: "898254680438",
  appId: "1:898254680438:web:5ac7c19da30a13dde40a57"


});
var messaging = null
if(firebase.messaging.isSupported()){
   messaging = initializedFirebaseApp.messaging();
}


/* messaging.usePublicVapidKey(
	// Project Settings => Cloud Messaging => Web Push certificates
  "BAHmiQxrLCg_Gy1h9H5CD6k1j0xB36XgWzx11-muvlExwiH3zAPES0lC_OVaWIaZ0qdxiUowlEk_-eo-Kv2cY_4"
); */


const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});

export { messaging, onMessageListener };

