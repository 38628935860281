import React from "react"
import { getParams, toUrlParams } from "../../services/location"
import { useSelector } from 'react-redux'
import Slider from './slider'
import Corporate from "./corporate"

import './categories.scss'

const Categories = (props) => {

  const data = useSelector(state => ({ ...state.app }))

  return (
    <Terms
      categories={data.categories}
      allFiles={data.files}
      location={props.location}
    />
  )

}

const Terms = ({ categories, location, allFiles }) => {



  const { p, section, c } = getParams(location.search)

  const product = p
  const category = c
  //console.log(location.search, product, section, category)

  //console.log( 'Terms', terms)

  const f2 = allFiles.filter(f => f.termsIds.includes(product) || f.termsIds.includes(section))

  const items = categories
    .filter(term => term.vid === "categories" && term.parent === "0")
    .map(term => {
      //console.log(term, category)

      const active = term.id === category
      const n = f2.filter(f => f.termsIds.includes(term.id)).length
      const url = toUrlParams(location.search, { c: term.id, sub: '', id: '' })

      //console.log(location.search, url, active)

      return { ...term, active: active, filesNumber: n, link: url }
    })

  const itemsSorted = items.sort((a, b) => parseInt(a.weight) > parseInt(b.weight) ? 1 : -1)
  //console.log(itemsSorted)

  return (
    <>
      {section === '98' && <Corporate />}

      <div className="show-desktop">
        <Slider terms={itemsSorted} corporate={section === '98'} />
      </div>

      <div className="show-mobile">
        <Slider terms={itemsSorted} corporate={section === '98'} mobile />
      </div>


    </>
  )

}

export default Categories
