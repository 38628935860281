import React, {Component} from 'react';


// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

const SearchContext = React.createContext({
  /* files:[],
  terms:[],
  vocabularies:[],
  menu:[],
  isLoading:true, */
});

//import Spinner from 'react-bootstrap/Spinner'

class SearchProvider extends Component {

  /*
    data
    ui
    app
  */
  state = {

    show:false,

    keywords:'',

    //terms:[],

    products:[],
    treatments:[],
    categories:[],

    langs:[],
    extensions:[],


    options:{}
  };

/*
  isBrowser = () => {
    return typeof window !== "undefined"
  } */

  componentDidMount(){

/*
    if(  typeof window !== "undefined" ){
      const localState = JSON.parse(localStorage.getItem("app"))
      console.log( this.state )
      console.log( localState )
      if( localState ){
        this.setState(localState)
      }
      this.mount = true
    } */
  }



/*
  toLocalStorage = (item) => {
    this.isBrowser() && window.localStorage.setItem("app", JSON.stringify(item))
  }
 */

  dispatch = ( action ) => {

    //console.log('dispath' , action)

    const newState = this.reduce(this.state , action )

    //console.log('newState' , newState )

    this.setState(newState);
    //this.toLocalStorage(newState);

  }

  reduce = (state , action ) => {
    //const newState = {...state}

    switch(action.type) {

      case 'KEYWORDS':
        return Object.assign({}, state, {keywords:action.data} );


      case 'ADDTERM':
        const terms = [...this.state[action.data.vid], action.data.id]
        const s = {}
        s[action.data.vid] = terms
        return Object.assign({}, state, s );

      case 'REMOVETERM':
        //console.log(this.state[action.data.vid])
        const terms0 = this.state[action.data.vid].filter( t => t !== action.data.id )
        //console.log( terms0 )
        const s0 = {}
        s0[action.data.vid] = terms0

        return Object.assign({}, state, s0 );


      case 'SHOWMODAL':
        return Object.assign({}, state, {show:action.data});



      default:
        throw new Error('action type does not exist');
    };

  }


  render() {
    //const state = this.state

    //console.log( this.props )

    const state = {...this.state , options:this.props.options}
    return (
      <SearchContext.Provider
        value={{
          //...this.state,
          ...state,
          dispatch:this.dispatch,
        }}
      >
        {this.props.children}
      </SearchContext.Provider>
    );
  }
}


export {SearchContext, SearchProvider } ;