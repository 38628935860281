import React from "react"
import { Link } from "@reach/router"
import { getParams } from "../../services/location"
import { useSelector } from 'react-redux'

const Products = (props) => {

  const {/* terms, */ products, categories } = useSelector((state) => state.app)

  return (
    <Terms
      //terms={terms}
      products={products}
      categories={categories}
      {...props}
    />
  )
}


const Terms = ({ /* terms, */ params, products, categories, location }) => {

  //console.log( products )
  //const { ui } = params

  const ui = getParams(location.search)
  const product = ui.p
  //console.log(ui)
  if (!products) return false;

  //const products = terms.filter(t => t.vid === 'products').sort((a,b)=> a.label > b.label ? 1 : -1)

  //console.log( products )

  //const categories = terms.filter( term => term.vid === "categories" && term.parent === "0")
  const topCats = categories.filter(term => term.parent === "0")
  //console.log( categories)
  const c = topCats.length > 0 ? topCats[0].id : '11'

  //console.log(product)
  //console.log(products)

  const items = products ? products.map(p => (
    <li key={p.id} className={(p.id === product) ? 'active' : ''}>
      <Link to={"/files/?p=" + p.id + '&c=' + c}>{p.label}</Link>
    </li>
  )) :
    <li>No Products</li>
    ;

  return (
    <div className="products">
      <div className="inner">
        {/* <div className="mask">&nbsp;</div> */}
        <ul>{items}</ul>
      </div>
    </div>
  )
}

export default Products
