

const getParams = (search) => {
  //console.log( search )
  const searchParams = new URLSearchParams(search);
  //console.log(searchParams.get('p'))
  return {
    section: searchParams.get('section') || '',
    p: searchParams.get('product') || searchParams.get('p') || '',
    c: searchParams.get('category') || searchParams.get('c') || '',
    sub: searchParams.get('sub') || '',
    words: searchParams.get('words') || '',
    language: searchParams.get('language') || '',

    latest: searchParams.get('latest') || '',
    favorites: searchParams.get('favorites') || '',

    // for search
    keywords: searchParams.get('keywords') || '',
    products: searchParams.get('products') || '',
    treatments: searchParams.get('treatments') || '',
    categories: searchParams.get('categories') || '',
    langs: searchParams.get('langs') || '',
    extensions: searchParams.get('extensions') || '',

    id: searchParams.get('id') || '',
  };
}


const toUrlParams = (search, change) => {

  //console.log(search, change)

  const params = getParams(search)
  //console.log(params)

  const o = Object.assign({}, params, change)

  const searchParams = new URLSearchParams();
  for (let [key, value] of Object.entries(o)) {
    if (value) {
      searchParams.set(key, value);
    }
  }
  //console.log( searchParams.toString() )
  return searchParams.toString();
}

const filterFiles = (search, files) => {


  const params = getParams(search)

  //console.log(params )

  //const item = files.filter( a => a.id === '2226')
  //console.log( item[0].termsIds )

  let items = files
  for (let [key, value] of Object.entries(params)) {
    if (value) {
      switch (key) {
        case 'product':
        case 'p':
        case 'category':
        case 'c':
        case 'sub':
        case 'section':
          items = items.filter(f => { return f.termsIds.includes(value) })
          break;

        default:
          break;
      }

    }
  }

  return items

}


export { getParams, toUrlParams, filterFiles }