import React/* , { useState } */ from "react"
import Modal from 'react-bootstrap/Modal'
import CloseButton from 'react-bootstrap/CloseButton'
import { SearchProvider, SearchContext } from "./context"
import Controles from "./controls"
import Tags from "./tags"
import ShowResultsButton from "./button"
import SearchImg from '../../images/search.png'
import { connect, useDispatch } from "react-redux"
import ArrowLeftThin from "../../images/arrow-left-thin.svg";
import "./modal.scss"

class AdvSearchModal extends React.Component {

  render() {

    const { options } = this.props

    return (
      <SearchProvider options={options}>
        <SearchModal />
      </SearchProvider>
    )
  }
}



class SearchModal0 extends React.Component {

  close = () => {
    const { dispatch } = this.props
    dispatch({ type: "search-modal/close" })
  }

  render() {

    const { show } = this.props
    //const show = true

    //classes = isMobile() ? ' modal-100w ' : ' modal-90w'
    return (

      <Modal show={show} onHide={this.close} dialogClassName={"modal-90w modal-search "} >
        {/* <Modal.Header closeButton>
          <Modal.Title>Advanced Search</Modal.Title>
        </Modal.Header> */}
        < Modal.Body >

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-controls">

                <Controles />
              </div>

              <div className="col-md-6 col-tags">
                <CloseButton className="btn-close-modal" onClick={() => this.close()} />
                <div className="show-desktop">
                  <img src={SearchImg} alt="search" />
                  <Tags />
                  <ShowResultsButton />
                </div>

                <div className="show-mobile">
                  <ShowResultsButton />
                  <img src={SearchImg} alt="search" width="50%" />
                  <Tags />
                </div>

              </div>

            </div>
          </div>

        </ Modal.Body>
      </Modal>

    )
  }
}


const SearchModal = connect((state) => ({ show: state.app.isSearchModalOpen }))(SearchModal0)
//connect
//SearchModal.contextType = SearchContext


class ModalOpenButton extends React.Component {

  click = () => {
    console.log('open')
    //const {dispatch } = this.context
    //dispatch({type:"SHOWMODAL" , data: true })
  }

  render() {
    return <button className="advanced " onClick={this.click}>Advanced</button>
  }
}
ModalOpenButton.contextType = SearchContext


const AdvancedButton = () => {
  const dispatch = useDispatch();
  return <button className="advanced " onClick={() => { dispatch({ type: 'search-modal/open' }) }}>Advanced</button>
}



const SearchBackButton = () => {
  const dispatch = useDispatch();
  return <button className="advanced-back " onClick={() => { dispatch({ type: 'search-modal/open' }) }}><Arrow /> BACK TO SEARCH</button>
}


const Arrow = () => {

  return <img src={ArrowLeftThin} alt="back to search" />
}

export { ModalOpenButton, AdvancedButton, SearchBackButton }
export default AdvSearchModal