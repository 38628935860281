import React from "react"
import Facebook from "../../images/social/Facebook.svg"
import Instagram from "../../images/social/instagram.svg"
import Youtube from "../../images/social/icon_2.svg"
import LinkedIn from "../../images/social/icon.svg"
import TikTok from "../../images/social/tiktok.svg"
import "./sidebar.scss"

const SocialMedia = () => {

  const links = [
    {label: 'Facebook' , img: Facebook, url: "https://www.facebook.com/almalasersint"},
    {label: 'Instagram' , img: Instagram, url: "https://www.instagram.com/alma.lasers.international/"},
    {label: 'LinkedIn' , img: LinkedIn, url: "https://www.linkedin.com/organization/5317085"},
    {label: 'Youtube' , img: Youtube, url: "https://www.youtube.com/channel/UC2VeP2d4WnOyQpuLxMRf0bA"},
    {label: 'TikTok' , img: TikTok, url: "https://www.tiktok.com/@alma.lasers.official"},
  ]

  const items = links.map( (item, i) => (
    <a href={item.url} target="__blank" className="btn-round" title={item.label} key={i}><img src={item.img} alt={item.label} /></a>
  ))
  return (
    <div className="social-media">
      {items}
    </div>
  )
}


export default SocialMedia
