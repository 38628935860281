//import React from "react";
import { useDispatch } from 'react-redux';

const CheckToken = ({ children }) => {

  const dispatch = useDispatch()

  const doTheCheck = () => {
    dispatch({ type: 'token/check', payload: {} })
  }

  setInterval(doTheCheck, '60000')
  dispatch({ type: 'token/check', payload: {} })

  return children
}

export default CheckToken;
