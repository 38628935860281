import React from "react"
import Spinner from 'react-bootstrap/Spinner'

import { ArrowRepeat } from "react-bootstrap-icons"
import { useSelector, useDispatch } from 'react-redux'
//import user from "../../state/reducers/user"

const ReloadData = () => {

  const {isLoggedIn,isLoading}  = useSelector((state ) => ({
    isLoggedIn: state.user.isLoggedIn,
    isLoading:state.app.isLoading
  }))
  const dispatch = useDispatch()

  if( isLoggedIn   ){
    return (
      <>

        {isLoading && <Spinner animation="border" ></Spinner>}

        <button
          onClick={() => (
            dispatch({type:'app/load'})
          )}
          title="reload"
          className="btn btn-light btn-reload"
          aria-label="reload"
        >
          <ArrowRepeat size={12} />
        </button>

      </>
    )

  }
  return false

}


export default ReloadData