import React/* , {useState} */ from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
//import Card from '../components/video/card'
import Videos from '../components/video/videos'
import { useSelector, useDispatch } from 'react-redux'
//import "../components/webinar/block.scss"
//import Modal from 'react-bootstrap/Modal'

const VideosPage = (props) => {

  const { nodeId } = props

  const dispatch = useDispatch();
  const nodes = useSelector(state => state.app.nodes)
    .filter(node => node.type === 'al_content')

  const node = nodes.find(n => n.id === nodeId)

  if (!node) return <div></div>

  const videos = node.videos.map((v, i) => ({
    ...v,
    thumb: node.videos_thumbnails[i]
  }))



  return (
    <Layout location={props.location} title={node.title}>
      <SEO title={node.title} />
      <Videos videos={videos} dispatch={dispatch} />
    </Layout>
  )
}

export default VideosPage
