import React from "react"
import { Link/* , navigate */ } from "@reach/router"
import Logout from "../user/logout"
import Products from "../category/products"
import { TutorialLink } from "../button/tutorial"
import { LogoSidebar as Logo } from "./logo"
import SocialMedia from "./social-media"
import { Restricted, Public } from "../user/elements";
import GearImg from "../../images/gear.png"
import "./sidebar.scss"
import { Star, Envelope, Eye } from "react-bootstrap-icons"

const Sidebar = ({ location }) => {


  return (
    <nav className="sidenav">
      <div className="sidenav-top">

        <div className="show-desktop">
          <div className="sidenav-block sidenav-logo">
            <Logo />
          </div>
        </div>
        <Restricted>
          <div className="sidenav-block sidenav-propmo" >
            <div className="propmo-new">BRAND NEW:</div>
            <div><a className="propmo-link" target="_blank" href="/guidelines">Our Online Brand Guidelines</a></div>
            <div className="mt-2"><a className="propmo-btn" target="_blank" href="/guidelines">Check it out</a></div>
          </div>
        </Restricted >
        <Public>
          <div className="sidenav-block sidenav-public">
            <div><Link to={'/register'}>Register</Link></div>
            <div><Link to={'/'}>Login</Link></div>
          </div>
        </Public>
        <Restricted>

          <div className="sidenav-block sidenav-products">
            <h5>Alma products</h5>
            <div>
              <Products location={location} />
            </div>
          </div>


          <div className="sidenav-block sidenav-links">
            {/* <Sections /> */}
            <div><Link to={'/files/?section=98&c=11'}>Corporate Collateral</Link></div>
            <div><Link to={'/contact'}>Contact & Support</Link></div>
            <div className="tutorial-link"><TutorialLink /></div>

            <div className="show-mobile">
              <div>
                <Link to={'/notifications'}>
                  <Envelope size="22" style={{ color: "#968aa3" }} />
                  <span className="ms-3">Notifications</span>
                </Link>
              </div>
              <div>
                <Link to={'/favorites'}>
                  <Star size="22" style={{ color: "#968aa3" }} />
                  <span className="ms-3">Favorites</span>

                </Link>
              </div>
              <div><Link to={'/history'}><Eye size="22" style={{ color: "#968aa3" }} /><span className="ms-3">History</span>
              </Link></div>
            </div>
          </div >

        </Restricted >

      </div>


      <div className="sidenav-bottom">
        <div className="sidenav-copyright">
          <Restricted>
            <SocialMedia />
            <hr />
            <div className="footer-actions">
              <Link to={'/profile'} className="profile"><img src={GearImg} alt="icon" style={{ marginRight: 10 }} />Profile</Link>
              <Logout />
            </div>
          </Restricted>
          <p className="copyright">
            Copyright © {new Date().getFullYear()} Alma Lasers Ltd. <br />All rights reserved.
          </p>
        </div>
      </div>

    </nav >
  )
}


export default Sidebar
