import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import defaultImage from "../images/Alma_logo_2018.jpg"
import DateFomat from '../components/date-format'
import { useSelector, useDispatch } from 'react-redux'


const SearchPage = (props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => ({ ...state.app }))
  const notifications = data.notifications.sort((a, b) => a.created < b.created ? 1 : -1)
  //console.log( notifications )

  return (
    <Layout location={props.location} title="Notifications">
      <SEO title="Notifications" />

      <Notif notifications={notifications} dispatch={dispatch} />
    </Layout>

  )
}

//const Notif = (props ) => {
class Notif extends React.Component {

  componentDidMount() {
    //console.log('once')
    // find all not read
    // fire a dispatch cmd

    const { dispatch, notifications } = this.props
    const unread = notifications.filter(n => n.is_read === '0').map(n => n.is_read_id)

    if (unread.length > 0) {
      dispatch({ type: 'notification/read', payload: { ids: unread } })
    }

    //console.log( unread)
  }


  render() {

    const { notifications } = this.props
    //console.log( notifications )

    const cards = notifications.map(item => {
      return (
        <div className="row" key={item.id} >
          <div className="col">
            <Card {...item} />
          </div>
        </div>
      )
    })


    return (
      <div className="container-fluid container-app">
        <div className="row">
          <div className="col">
          </div>
        </div>
        {cards}
      </div>
    )
  }
}



const Card = (props) => {

  //console.log(props)
  const { title, push_link, created,/*  changed, */ icon, is_read_id } = props
  const { url } = icon
  const dispatch = useDispatch()


  return (
    <>
      <div className="show-mobile">
        <div className="card card-notification" style={{ marginBottom: "30px" }}>
          <div className="card-body">

            <div className="">
              <div className="mb-3">
                <img src={url.includes('system/files') ? defaultImage : url} alt="..." width="200" />
              </div>

              <div className="">

                <h5 className="card-title">{title}</h5>
                <p><a href={push_link} target="__blank" >{push_link}</a></p>
                <DateFomat timestamp={created} />

                <div className="remove-button">
                  <button className="btn" onClick={() => { dispatch({ type: 'notification/remove', payload: { id: is_read_id } }) }}>X Remove</button>
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>

      <div className="show-desktop">
        <div className="card card-notification" style={{ marginBottom: "30px" }}>
          <div className="card-body">

            <div className="d-flex">
              <div className="flex-shrink-0">
                <img src={url.includes('system/files') ? defaultImage : url} alt="..." width="200" />
              </div>
              <div className="flex-grow-1 ms-3">

                <h5 className="card-title">{title}</h5>
                <p><a href={push_link} target="__blank" >{push_link}</a></p>
                <DateFomat timestamp={created} />

                <div className="remove-button">
                  <button className="btn" onClick={() => { dispatch({ type: 'notification/remove', payload: { id: is_read_id } }) }}>X Remove</button>
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>
    </>
  )
}



export default SearchPage
