import React from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Spinner from 'react-bootstrap/Spinner'
import { connect } from 'react-redux'
//import {  Link  } from "@reach/router"

class Recovery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  validate = (values) => {
    const errors = {};

    /* if (!values.username_or_email) {
      errors.username = 'Required';
    } */

    if (!values.email) {
      errors.email = 'Required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }



    return errors;
  }


  submit = (values, { setSubmitting }) => {

    //console.log( this.props )
    //console.log( values )

    const { dispatch } = this.props

    dispatch({
      type: 'recover/init',
      payload: {
        email: values.email
      }
    })

  }



  render() {

    const {/* options, */ loading, messages } = this.props

    //console.log( this.props )

    //const {name } = this.state

    const msgs = messages.map((m, i) => (
      <div className="alert alert-danger" key={i} role="alert">{m}</div>
    ))

    const initialValues = {
      username_or_email: '',
    }

    return (

      <Formik
        initialValues={initialValues}
        validate={this.validate}
        onSubmit={this.submit}
      >
        {({
          isSubmitting,
          isValid
          /* and other goodies */
        }) => (
          <Form>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  {msgs}
                </div>
              </div>
              <div className="row">

                <div className="col">
                  <InputField name="email" title="E-mail" required={true}>
                    {/* <div className="form-text">we send you token with witch you can reset your password.</div> */}
                  </InputField>
                </div>

              </div>

              <div className="row">
                <div className="col">
                  <button type="submit" className="btn btn-primary btn-wide" disabled={!isValid}>
                    {loading && <Spinner animation="border" ></Spinner>}
                    Reset Password
                  </button>

                </div>
              </div>

              {/* <div className="row">
                <div className="col">
                  <Link to={"/password-reset"}>move on</Link>

                </div>
              </div> */}

            </div>
          </Form>
        )}
      </Formik>



    );
  }


}

const InputField = ({ name, title, type = 'text', required = false, children }) => {

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <label htmlFor={name + "Input"} className="form-label">{title} {required ? ' *' : ''}</label>
        </div>

      </div>


      <div className="row">

        <div className="col">

          <Field type={type} name={name} className="form-control" id={name + "Input"} required={required} />
          <ErrorMessage name={name} component="div" className="alert alert-danger" />
          {children}
        </div>
      </div>


    </div>
  )
}

export default connect(state => ({ ...state.recover }))(Recovery)