import React from 'react';
import { Provider } from 'react-redux';
//import { createStore, applyMiddleware  } from 'redux';
//import rootReducer from './index';
//import {loadState, saveState} from './localStorage';
//import {asyncFunctionMiddleware as auth} from './auth';
import store from './store';


//const middlewareEnhancer = applyMiddleware(auth)


//const persistedState = loadState();

//const createStore = () => reduxCreateStore(rootReducer , persistedState );

//const store = createStore(rootReducer , persistedState,  middlewareEnhancer );

/* store.subscribe(() => {
  saveState( store.getState() );
});

store.subscribe(() => console.log(store.getState())) */

//console.log(store.getState())


/* export default ({ element }) => (
  <Provider store={createStore()}>{element}</Provider>
); */

const MyProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);
export default MyProvider