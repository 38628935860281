import Axios from "axios"
import { endPoint } from "../config"

const data = storeAPI => next => action => {

  //console.log('action type:', action.type)

  if (action.type === 'app/download-success') {
    if (typeof window !== "undefined") {
      window.open(action.payload.url);

      // create link
      // set attributes
      // click
      // remove link
    }
  }


  next(action)

  if (action.type === 'app/load') {
    const state = storeAPI.getState()
    loadData(state.user.jwt, storeAPI.dispatch)
  }

  if (action.type === 'app/load-sccuess') {
    const state = storeAPI.getState()

    loadFavorites(state.user.jwt, storeAPI.dispatch)
    loadHistory(state.user.jwt, storeAPI.dispatch)

    // either or
    //if(state.user.user.roles.includes('medical_doctor_int_') ||  state.user.user.roles.includes('surgical_int')  ){
    loadTopDownloads(state.user.jwt, storeAPI.dispatch)
    //} else {
    loadLatestFiles(state.user.jwt, storeAPI.dispatch)
    //}

    if (state.user.user.roles.includes('administrator')) {
      loadStats(state.user.jwt, storeAPI.dispatch)
    }


  }

  if (action.type === 'user/login-success') {
    const state = storeAPI.getState()
    loadData(state.user.jwt, storeAPI.dispatch)
  }

  if (action.type === 'user/logout') {
    // redirect to /
    //navigate('/')
  }


  if (action.type === 'app/favorites-set') {
    const state = storeAPI.getState()
    SetFavorite(state.user.jwt, storeAPI.dispatch, action.payload)
  }


  if (action.type === 'app/download') {
    const state = storeAPI.getState()
    download(state.user.jwt, storeAPI.dispatch, action.payload)
  }

  if (action.type === 'app/contact-send') {
    const state = storeAPI.getState()
    contact(state.user.jwt, storeAPI.dispatch, action.payload)
  }

  if (action.type === 'video/load') {
    const state = storeAPI.getState()
    requestVideo(state.user.jwt, storeAPI.dispatch, action.payload)
  }

}


const loadData = (jwt, dispatch) => {


  //console.log( jwt , dispatch)


  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.get(
    endPoint + `/api/kgmedia-app-data?_format=json`,
    { headers: headers }
  )
    .then(res => {

      // do some processing here

      const categoriesPairs = res.data.terms
        .filter(t => t.vid === "categories")
        .filter(t => t.parent !== '0')
        .map(t => [t.id, t.parent])
      //console.log(categories );
      const categoriesMap = Object.fromEntries(categoriesPairs);
      //console.log(categoriesMap );

      //console.log( res.data.nodes );

      //console.log(res.data.files.filter(i => i.id === '2238'))

      const files = res.data.files
        .sort((a, b) => { return parseInt(b.changed) - parseInt(a.changed) })
        .map(f => ({
          ...f,
          termsIds: normelizeCategories(f, categoriesMap),
          favorite: false,
          stats: { views: 0, downloads: 0 },
          files: normelizeFiles(f)
        }))
        .filter(f => (f.file !== false || (f.link_external && f.link_external.length > 0)))
        .map(f => ({
          ...f,
          additional_languages: f.additional_languages.filter(a => a.file !== false)
        }))
      // remove additinal languages without file additional_languages

      //console.log(files.filter(i => i.id === '2238'))

      const webinars = Object.values(res.data.nodes)
        .filter(n => n.type === "webinar")
        .sort((a, b) => a.created < b.created ? 1 : -1)
        .map(w => ({ ...w, link: w.event_link }))


      // ==== all avaliable langs
      const langs = files
        .map(f => {
          const ex = f.files
            .map(ff => ff.language)
            .filter((v, i, a) => a.indexOf(v) === i)
            .reduce((elem1, elem2) => elem1.concat(elem2), [])
            .map(e => e.charAt(0).toUpperCase() + e.slice(1))
          return ex
        })
        .reduce((elem1, elem2) => elem1.concat(elem2), [])
        .filter((v, i, a) => a.indexOf(v) === i)



      const categories = res.data.terms
        .filter(t => t.vid === "categories")
        .filter(t => { // check if has any files because little sense to show empty categy
          const tf = files.filter(f0 => f0.termsIds.includes(t.id))
          return tf.length > 0
        })

      //console.log( categories )

      const products = res.data.terms
        .filter(t => t.vid === 'products')
        .sort((a, b) => parseInt(a.weight) > parseInt(b.weight) ? 1 : -1)


      const blocks = res.data.blocks
        .sort((a, b) => parseInt(a.weight) < parseInt(b.weight) ? 1 : -1)

      //console.log( blocks );


      dispatch({
        type: 'app/load-sccuess',
        payload: {
          vocabularies: res.data.vocabularies,
          terms: res.data.terms,

          categories: categories,
          products: products,

          notifications: res.data.notifications,
          nodes: res.data.nodes,
          blocks: blocks,
          promoBlock: res.data.promoBlock,
          webinars: webinars,
          events: res.data.events,
          media: res.data.inMedia,

          corporateBlock: res.data.corporateBlock,

          files: files,
          isLoading: false,

          search: {
            langs: langs
          }
        },
        //jwt:jwt
      })


    })
    .catch(error => {
      //console.log(error)

      handlerError(error, dispatch);

    })


}


const normelizeCategories = (file, categoriesMap) => {
  //f.categories
  // if the category has parent then insert the parent as well
  const f = file
  const tids = f.products.concat(f.treatments, f.categories).map(i => i.id)

  const ids = f.categories.map(c => c.id)
  //console.log( 'ids' , ids )

  ids.forEach(id => {
    if (categoriesMap[id]) {
      //console.log( categoriesMap[id])
      tids.push(categoriesMap[id])
    }
  });

  // if no products make sure we have section id 98. this coaused a bug
  if (f.products.length === 0) {
    tids.push('98')
  }

  // filter out duplicates
  const unique = tids.filter((v, i, a) => a.indexOf(v) === i);
  //console.log( unique)

  return unique

}


const normelizeFiles = (node) => {

  const { additional_files, additional_languages, file, language } = node

  /* if (node.id === '1787') {
    console.log(additional_files)
    console.log(additional_languages)
  } */

  let files = []
  if (file) {
    files.push({
      file: file,
      description: '',
      language: language || 'English'
    })
  }
  const f2 = additional_files ? additional_files.filter(f => f).map(f => ({
    file: f,
    description: f.description,
    language: language || 'English'
  })) : []

  const f3 = additional_languages ? additional_languages.filter(f => f.file).map(f => ({ file: f.file, description: f.description, language: f.language || 'English' })) : []
  //files = files.concat(f2, additional_languages.filter(f => f.file).map(f => ({ file: f.file, language: f.language || 'English' })))
  files = files.concat(f2, f3)


  // here go over kangys abd make sure hebrew is Hebrew

  return files.map(f => ({ ...f, language: f.language === 'hebrew' ? 'Hebrew' : f.language }))

}



const loadFavorites = (jwt, dispatch) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.get(
    endPoint + `/api/kgmedia-favorites?_format=json`,
    { headers: headers }
  )
    .then(res => {
      dispatch({ type: 'app/favorites-success', payload: res.data/* , jwt:jwt */ })
    })
    .catch(error => {
      //console.log(error)

      handlerError(error, dispatch);


    })

}


const loadHistory = (jwt, dispatch) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.get(
    endPoint + `/api/kgmedia-download-history?_format=json`,
    { headers: headers }
  )
    .then(res => {
      //console.log( res.data )
      dispatch({ type: 'app/history-success', payload: res.data.filter(item => item.nid !== "0") })
    })
    .catch(error => {
      handlerError(error, dispatch);
    })

}


const loadTopDownloads = (jwt, dispatch) => {



  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.get(
    endPoint + `/api/kgmedia-top-downloaded?_format=json`,
    { headers: headers }
  )
    .then(res => {

      //console.log( res.data )
      dispatch({ type: 'app/top-downloaded-success', payload: res.data })
    })
    .catch(error => {

      handlerError(error, dispatch);


    })

}


const loadLatestFiles = (jwt, dispatch) => {



  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.get(
    endPoint + `/api/kgmedia-top-updated?_format=json`,
    { headers: headers }
  )
    .then(res => {
      //console.log(res.data)
      dispatch({ type: 'app/top-updated-success', payload: res.data })
    })
    .catch(error => {
      handlerError(error, dispatch);
    })

}






const loadStats = (jwt, dispatch) => {

  //console.log('loadStats')
  //app/stats-success

  // only for admins

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.get(
    endPoint + `/api/kgmedia-stats?_format=json`,
    { headers: headers }
  )
    .then(res => {
      dispatch({ type: 'app/stats-success', payload: res.data })
    })
    .catch(error => {
      handlerError(error, dispatch);
    })

}



const SetFavorite = (jwt, dispatch, data) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.post(
    endPoint + `/api/kgmedia-favorites?_format=json`,
    data,
    { headers: headers }
  )
    .then(res => {

      dispatch({
        type: 'app/favorites-set-success',
        payload: { id: data.nid, value: (data.action === 'insert' ? true : false) },
        jwt: jwt
      })

    })
    .catch(error => {
      //console.log(error)
      handlerError(error, dispatch);
      console.log(error.response)
      //console.log(error.request)

      /* if( error.response !== undefined ){
        console.log(error.response.data)

        if( error.response.data.message === "Expired token" ){
          dispatch({
            type:'user/expired-token',
            payload:{
              messages: "Expired token"
            }
          })
        }

        if( error.response.data.message === "Signature verification failed" ){
          dispatch({
            type:'user/SIGNATURE_VARIFICATION_FAILED',
            payload:{
              messages: "Signature verification failed"
            }
          })
        }

      }

      if( error.request !== undefined ){
        console.log( error.request )
      } */

    })

}


const download = (jwt, dispatch, payload) => {


  const { nid, item, path } = payload

  const payload2 = {
    uri: item.uri,
    path: path
  }


  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.post(endPoint + `/api/kgmedia-s3presign`, payload2, { headers: headers })
    .then(res => {
      //console.log(res);
      //console.log(res.data);

      /* this.setState({
        url:res.data.url,
        isLoading:false
       }) */

      // send action  app.download

      dispatch({
        type: 'app/download-success',
        payload: {
          fid: item.fid,
          //nid:nid,
          //timestamp: ( Date.now() / 1000 )
          url: res.data.url,
        },

      })

      dispatch({
        type: 'app/history',
        payload: {
          fid: item.fid,
          nid: nid,
          timestamp: (Date.now() / 1000)
        },
      })

      /* dispatch({
        type:'app/download-success',
        payload:{
          fid:item.fid,
          nid:nid,
          timestamp: ( Date.now() / 1000 )
        }
      }) */

      //console.log(this.props.dispatch)


      if (typeof window !== "undefined") {
        //window.open(res.data.url);
      }

      // so here we havce url - then we can use it
    })
    .catch(error => {

      handlerError(error, dispatch);
      /* //console.log(error)
      console.log(error.response)
      //console.log(error.request)

      if( error.response !== undefined ){
        console.log(error.response.data)

        if( error.response.data.message === "Expired token" ){
          dispatch({
            type:'user/expired-token',
            payload:{
              messages: "Expired token"
            }
          })
        }

        if( error.response.data.message === "Signature verification failed" ){
          dispatch({
            type:'user/SIGNATURE_VARIFICATION_FAILED',
            payload:{
              messages: "Signature verification failed"
            }
          })
        }

      }

      if( error.request !== undefined ){
        console.log( error.request )
      }
 */
    })

}




const contact = (jwt, dispatch, payload) => {


  const { body } = payload

  const payload2 = {
    message: body
  }


  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.post(endPoint + `/api/kgmedia-contact`, payload2, { headers: headers })
    .then(res => {
      console.log(res);
      console.log(res.data);

      // send action  app.download

      dispatch({
        type: 'app/contact-send-success',
        payload: {},
      })

    })
    .catch(error => {
      handlerError(error, dispatch)
    })

}


const requestVideo = (jwt, dispatch, payload) => {

  const payload2 = {
    uri: payload.uri
  }

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.post(endPoint + `/api/kgmedia-s3presign`, payload2, { headers: headers })
    .then(res => {
      console.log(res);
      console.log(res.data);

      dispatch({
        type: 'video/load-success',
        payload: {
          url: res.data.url,
        },
      })

    })
    .catch(error => {
      handlerError(error, dispatch);
    })

}

const handlerError = (error, dispatch) => {

  console.log(error)
  console.log(error.response)
  //console.log(error.request)

  if (error.response !== undefined) {

    console.log(error.response.data)



    if (error.response.data.message === "Expired token") {
      dispatch({
        type: 'user/expired-token',
        payload: {
          messages: "Expired token"
        }
      })
    }

    if (error.response.data.message === "Signature verification failed") {
      dispatch({
        type: 'user/SIGNATURE_VARIFICATION_FAILED',
        payload: {
          messages: "Signature verification failed"
        }
      })
    }

    if (error.response.status === 403) {

      console.log('403', error.response)
      dispatch({
        type: 'url/forbidden', // check if token is still not expiered. if expiered then logout
        payload: {
          messages: error.response.statusText
        }
      })
    }

  } else if (error.request !== undefined) {
    console.log(error.request)
  } else {
    console.log(error.request)
  }

}

export { data }