const initialState = {

  loading: false,
  loaded: false,
  options:{
    types:[],
    titles:[],
    products:[],
    countries:[]
  },

  messages:[],

  submitting:false,
  /* success:false, */

};


const Reducer =  (state = initialState, action) => {
  switch (action.type) {
    case 'registration/options':
      return { ...state,loading:true, messages:[]  };

    case 'registration/options-success':
      return { ...state, loaded:true, options: action.payload.options, loading:false, messages:[]  };

    case 'registration/init':
      return { ...state, submitting:true, messages:[]  };
    case 'registration/init-success':
      return { ...state, submitting:false, messages:[] };
    case 'registration/init-failure':
      return { ...state, submitting:false, messages:[action.payload.message]  };

    default:
      return state;
  }
};

export default Reducer

