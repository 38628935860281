import React from "react"
//import {SearchContext} from './context'
import { XCircleFill } from "react-bootstrap-icons"
import { connect } from "react-redux"
import { navigate } from "@reach/router"
import { getOptions, getParams, buildSearchQuery } from "./functions"
import "./page-tags.scss"


class PageTags extends React.Component{

  click = (e) => {

    const id = e.currentTarget.id;

    const {location } = this.props
    const params = getParams( location.search)

    const parts = id.split(":")
    const p = parts[0]
    const v = parts[1]

    switch (p) {
      case 'keywords':
        params.keywords = ''
        break;

      case 'products':
      case 'treatments':
      case 'categories':
      case 'langs':
      case 'extensions':
        params[p]  = params[p].filter(t => t !== v )
        break;

      default:
        break;
    }

    const q = buildSearchQuery(params)
    navigate('/search/?' + q )

  }


  render(){

    const {location } = this.props

    const options = getOptions(this.props )


    const params = getParams( location.search)
    const {keywords} = params


    const tags = []
    if( keywords !== ''){
      tags.push(<li key="keywords">{keywords} <button id="keywords" onClick={this.click} aria-label="remove"><XCircleFill /></button></li>)
    }

    let term
    ['products','treatments','categories', 'langs', 'extensions'].forEach(k => {

      const ids = params[k]
      const terms = options[k]

      if( ids ){
        ids.forEach(id => {
          term = terms.find(t => t.id === id )
          //console.log(term )
          tags.push(<li key={id}>{term.label} <button onClick={this.click} aria-label="remove" id={k+":"+id}><XCircleFill /></button></li>)
        });
      }

    });

    return (

      <div className="page-tags">
        <ul>
          {tags}
        </ul>
      </div>


    )
  }
}

export default connect(state => ({...state.app}))(PageTags)