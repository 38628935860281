import React from "react"
import { navigate } from "@reach/router"
import { SearchContext } from './context'
import { buildSearchQuery } from './functions'
import {connect/* , useSelector, useDispatch */ } from "react-redux"


class Tags0 extends React.Component{

  handle = () => {
    //const {handle /* , options */ } = this.props
    //const { products ,treatments, categories} = this.state

    //handle( this.state )

    const q = buildSearchQuery(this.context)

    // close he modal

    const {dispatch } = this.props
    dispatch({ type:"search-modal/close" })

    //navigate('/search/?' + searchParams.toString() )
    navigate('/search/?' + q )
  }


  render(){

    return (

      <div className="show-results">
        <button  onClick={this.handle}>Show Results</button>
      </div>

    )
  }
}
Tags0.contextType = SearchContext

const Tags = connect()(Tags0)


export default Tags