import React from "react"
import { useSelector } from 'react-redux'
import { getParams } from "../../services/location"
import { SearchBackButton } from "../search/modal"
import './categories.scss'

const Categories = (props) => {

  const terms = useSelector(state => (state.app.terms))
  /*  const isLoggedIn = useSelector(state => (state.user.isLoggedIn))
   console.log(isLoggedIn)

   if (!isLoggedIn) return false */
  return (
    <Header {...props} terms={terms} />
  )
}

const Header = ({ terms, title, location }) => {

  //console.log( location )

  const p = location ? getParams(location.search) : {}
  const { product, category, section } = p

  //const dispatch = useDispatch();
  //console.log( p )

  //console.log(section)
  //console.log( title )
  let preTitle = ''
  let praimary = false
  let secondary = false
  if (product) {
    praimary = terms.find(t => t.id === product)
    secondary = terms.find(t => t.id === category)
  } else if (section) {

    praimary = terms.find(t => t.id === section)
    secondary = terms.find(t => t.id === category)
  } else {
    praimary = { label: title }

    if (title === 'Search results') {
      preTitle = <SearchBackButton />;
    }
  }

  return (
    <div className="files-header">
      {preTitle}
      {praimary && <h1>{praimary.label}</h1>}
      {secondary && " | "}
      {secondary && <h2>{secondary.label}</h2>}
    </div>
  )

}



export default Categories
