import React from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Spinner from 'react-bootstrap/Spinner'
import { connect } from 'react-redux'
//import {  Link  } from "@reach/router"

class Recovery extends React.Component {


  validate = ( values ) => {
    const errors = {};

    // rules for password ??

    if (!values.email) {
      errors.email = 'Required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }

    if (!values.token) {
      errors.token = 'Required';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    if (!values.password2) {
      errors.password2 = 'Required';
    }

    if (values.password !== values.password2 ) {
      errors.password2 = 'Passwords not same';
    }



    return errors;
  }

  submit = ( values, { setSubmitting } ) => {
    const { /* submit, email,*/  dispatch } = this.props
    //setSubmitting(false);
    //submit(values);

    console.log( values )

    dispatch({type:'recover/reset-pass', payload:{
      token:values.token,
      password: values.password,
      email: values.email
    }})
  }


  render() {

    console.log(this.props)
    const { email, messages, loading } = this.props

    //const {name } = this.state

    const msgs = messages.map( (m,i) => (
      <div className="alert alert-danger" key={i} role="alert">{m}</div>
    ))

    const initialValues = {
      password: '',
      password2: '',
      token:'',
      email:email,
    }

    return (

      <Formik
       initialValues={initialValues}
       validate={this.validate}
       onSubmit={this.submit}
     >
       {({
         isSubmitting,
         isValid
         /* and other goodies */
       }) => (
          <Form>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  {msgs}
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <InputField name="email" title="Email" required={true} type="email" autocomplete="false" />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <InputField name="token" title="Verification code" required={true} type="text" autocomplete="false" />
                </div>
              </div>

              {/* <div className="row">
                <div className="col">
                  <InputField name="email" title="Email" required={true} type="hidden" />
                </div>
              </div> */}



              <div className="row">

                <div className="col">
                  <InputField name="password" title="New password" required={true} type="password"  />
                </div>

              </div>

              <div className="row">

                <div className="col">
                  <InputField name="password2" title="Repeat password" required={true} type="password" />
                </div>

              </div>

              <div className="row">
                <div className="col">
                  <button type="submit" className="btn btn-primary btn-wide"  disabled={!isValid}>
                  { loading && <Spinner animation="border" ></Spinner> }
                    Save new password
                  </button>

                  {/* <div>is submitting: {isSubmitting}</div> */}

                </div>
              </div>
            </div>
          </Form>
        )}
     </Formik>


    );
  }


}

const InputField = ({name, title, type = 'text', required = false,  children, ...props }) => {

  const p2 = {}
  if( type === 'password'){
    p2.autoComplete = "new-password"
  }
  return (
    <div className="container container-field">
      <div className="row">
        <div className="col">
          <label htmlFor={name + "Input"} className="form-label">{title} {required ? ' *' : ''}</label>
        </div>

      </div>


      <div className="row">

        <div className="col">

          <Field type={type} name={name} className="form-control" id={name + "Input"} required={required} {...p2}  />
          <ErrorMessage name={name} component="div" className="alert alert-danger" />
          {children}
        </div>
      </div>


    </div>
  )
}

export default connect(state => ({...state.recover}))(Recovery)