import Axios from "axios"
import { navigate } from "@reach/router"
import { endPoint } from "../config"
import jwt_decode from "jwt-decode";

const auth = storeAPI => next => action => {

  //console.log('action type:', action.type)

  next(action)


  if (action.type === 'user/login') {
    login(storeAPI.dispatch, action.payload)
  }

  if (action.type === 'user/login-success') {

    const url = localStorage.getItem('destination')
    if (url !== null && url !== '') {
      localStorage.removeItem('destination')
      navigate(url)
    } else {
      navigate('/')
    }
  }

  if (action.type === 'user/logout') {
    navigate('/')
  }

  if (action.type === 'url/forbidden') {

    const state = storeAPI.getState()
    const jwt = state.user.jwt

    const decoded = jwt_decode(jwt);

    var exp = new Date(decoded.exp * 1000)
    var now = new Date()

    if (now > exp) {
      console.log('should log out')
      console.log('exp:', exp.toISOString())
      console.log('now:', now.toISOString())

      const dispatch = storeAPI.dispatch
      dispatch({ type: 'user/logout', payload: {} })
    }

  }


  if (action.type === 'token/check') {

    const state = storeAPI.getState()
    const jwt = state.user.jwt

    if (jwt) {

      const decoded = jwt_decode(jwt);

      var exp1 = new Date(decoded.exp * 1000)
      var now1 = new Date()

      if (now1 > exp1) {
        console.log('token expiered. logout imidiatly')
        //console.log('exp:', exp.toISOString())
        //console.log('now:', now.toISOString())

        const dispatch = storeAPI.dispatch
        dispatch({ type: 'user/logout', payload: {} })
      }

    }

  }


}


const login = (dispatch, payload) => {

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //"Access-Control-Allow-Origin": "*",
    }
  }

  Axios.post(endPoint + `/api/kgmedia-user-login`, payload, options)

    .then(res => {

      //console.log(res);
      //console.log(res.data);

      const user = res.data.user;
      const jwt = res.data.jwt;

      dispatch({ type: 'user/login-success', payload: { user: user, jwt: jwt } })

    })
    .catch(error => {

      // also dispatch action
      if (error.response !== undefined) {
        //console.log(error.response.data)
        dispatch({ type: 'user/login-error', payload: error.response.data.message })
      }
      if (error.request !== undefined) {
        console.log('error in request')
        console.log(error.request)

      }
    })

}
export { auth }