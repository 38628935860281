import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { useSelector, useDispatch/* , connect  */} from 'react-redux'
import { navigate, Link } from "@reach/router"
import {Restricted, Public} from '../components/user/elements'

const MasqueradePage = (props) => {

  //const u = useSelector(state => ({...state.user}))
  //console.log(u)
  const data = useSelector(state => ({...state.masquerade}))

  //const notifications  = data.notifications.sort((a,b) => a.created < b.created ? 1 : -1)

  //console.log( data );

  const { loaded, isLoading, users/* , search */ } = data

  //console.log( loaded, isLoading, users)

  const dispatch = useDispatch()
  if( ! loaded && ! isLoading ){
    dispatch({type:'masquerade/load'});
    // fire the
  }

    //const { email, username, name, role } = search

    //const k = [email, username, name, role].join('-')

  //console.log( users );

  return (
    <Layout location={props.location} title="Masquerade">
      <SEO title="Masquerade" />


          <div className="container-fluid container-app">

            <div className="row">
              <div className="col">

                <Restricted>
                  <Users users={users} dispatch={dispatch} />
                  <hr />
                  <div>
                    <button className="btn" onClick={() => {dispatch({type:'masquerade/load'})}}>Reload</button>
                    <button className="btn" onClick={() => {dispatch({type:'masquerade/unmasquerade', payload:{}})}}>unmasquerade</button>
                  </div>
                </Restricted>

                <Public>
                  <div>
                    Unauthorized. try to <Link to={'/'}>Login</Link>
                  </div>
                </Public>

              </div>
            </div>

          </div>




    </Layout>

  )
}



class Users extends React.Component{

  state = {
    email:'',
    username:'',
    name:'',
    role:'',
  }

  onChange = (e) => {
    const v = {}
    v[e.target.id] = e.target.value
    this.setState(v)
  }

  render(){

    const { users, dispatch } = this.props
    const { email, username, /* name, */ role } = this.state

    const items = users
      .filter(u => role ? u.roles.includes(role) : true )
      .filter(u => email ? u.email.toLowerCase().includes(email.toLowerCase()) : true )
      .filter(u => username ? u.username.toLowerCase().includes(username.toLowerCase()) : true )

    return (
      <>
        <UserSearch onChange={this.onChange} {...this.state} users={users} />
        <div>Found: {items.length} Users</div>
        <UsersTable users={items.slice(0,50)} dispatch={dispatch} />
      </>
    )
  }
}


const  UserSearch  = (props) => {

  const { users, onChange } = props
  const { email, username, /* name, */ role } = props


  const roles = users
    .map(u => u.roles )
    .reduce( (elem1, elem2) => elem1.concat(elem2) , [] )
    .filter((x, i, a) => a.indexOf(x) === i)
    .map(v => ({label: v.replaceAll('_' , ' ') , value:v}))

  const options = roles.map(r => (
    <option value={r.value} key={r.value}>{r.label}</option>
  ))

  return (
    <div>
      <h3>Search User</h3>
      <form>
        email: {' '}
        <input type="text" id="email" value={email} onChange={onChange}   />
        {' '}

        username: {' '}
        <input type="text" id="username" value={username} onChange={onChange}  />
        {' '}

        {/*
        name
        <input type="text" id="name" value={name} onChange={onChange}  />
        */}


        role: {' '}
        <select  id="role" value={role} onChange={onChange}>
          <option value=""></option>
          {options}
        </select>

      </form>

    </div>
  )
  //}
}

//const UserSearch2 = connect(state=>({...state.masquerade}))(UserSearch)


const UsersTable = (props ) => {
  const {users,dispatch} = props

  const rows = users.map(item => {

    return (
      <tr key={item.id} >
        <td>{item.id}</td>
        <td>{item.email}</td>
        <td>{item.username}</td>
        <td>{item.roles.join(', ')}</td>
        <td><button className="btn btn-masquerade" onClick={ () => {dispatch({type:'masquerade/masquerade', id:item.id}); navigate('/')} }>masquerade</button></td>
      </tr>
    )
  })

  const headers = ['Id', 'Email', 'Username', 'Roles', 'Action'].map( (item,i) => (
      <th key={i}>{item}</th>
    ))


  return (

          <table className="table">
            <thead>
              <tr>{headers}</tr>
            </thead>

            <tbody>
              {rows}
            </tbody>

          </table>

  )
}



export default MasqueradePage
