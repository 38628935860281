const initialState = {
  user: {},
  jwt:'',
  isLoggedIn: false,
  messages: []
};


const Reducer = (state = initialState, action) => {
  //console.log(action)

  switch (action.type) {
    case 'user/login':
      return { ...state, messages:[]  };
    case 'user/login-error':
      return { ...state, messages:[action.payload] };
    case 'user/login-success':
      return { ...state, ...action.payload, messages:[], isLoggedIn:true };
    case 'user/logout':
      return { ...initialState };
    case 'user/expired-token':
      return { ...initialState };
    case 'masquerade/masquerade-sccuess':
      return { ...initialState, ...action.payload.new_user, isLoggedIn:true, messages:[] };
    case 'masquerade/unmasquerade':
      return { ...initialState, ...action.payload.old_user, isLoggedIn:true, messages:[] };
    default:
      return state;
  }
};

export default Reducer