import React from "react"
import PropTypes from "prop-types"
//import Header from "./header"
import "./layout2.scss"

const Layout = (props) => {

  const { /* title , */  children/* , location */, className = '', login = false } = props
  const { side1, side2 } = props


  const bg = login ? {
    backgroundImage: "url('/login-bg.svg')",
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed'
  } : {}
  return (
    <>
      {children}
      <div className="main-container">
        <main className={"container-fluid contianer-layout2 " + className} >
          <div className="row">

            <div className="col-sm-6 col-left border " style={bg}>
              {side1}
            </div>

            <div className="col-sm-6 col-right">
              {side2}
            </div>

          </div>

        </main>

      </div>
    </>
  )

}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object
}

export default Layout
