import React from "react"
import { SearchContext } from './context'
import CatMobile from './cat-mobile'

class List extends React.Component {


  state = {
    show: false
  }


  click = (e) => {
    //console.log( e.target , e.target.id , e.target.value )
    //console.log(  e.target.value ,  e.target.data )

    const id = e.target.id;
    const vid = this.props.id;
    const dispatch = this.context.dispatch

    //console.log( id , vid )
    //console.log( this.context )

    //return
    const selected = this.context[vid]
    //console.log( this.props )
    //console.log( selected )

    if (selected.includes(id)) {
      dispatch({ type: 'REMOVETERM', data: { id: id, vid: vid } })
    } else {
      dispatch({ type: 'ADDTERM', data: { id: id, vid: vid } })
    }

  }


  open = () => {
    this.setState({ show: true })
  }


  close = () => {
    this.setState({ show: false })
  }


  toggle = () => {
    this.setState({ show: !this.state.show })
  }


  render() {

    const { title, id } = this.props
    //const { show } = this.state

    const langs = this.context.options.langs
    const ids = this.context[id]

    const buttons = langs.map(t => {

      const active = ids.includes(t.id) ? 'active' : ''
      return (
        <button key={t.id}
          id={t.id}
          onClick={this.click}
          className={active}
        >{t.label} {t.selected}</button>
      )
    })

    return (
      <>
        <div className="show-desktop">
          <div className="categories">
            <h3 className="ff-mont-bold">{title}</h3>
            <div className="categories-list">
              <div className="categories-list-inner categories-list-short">
                {buttons}
              </div>
            </div>
          </div>
        </div>

        <div className="show-mobile">

          <CatMobile title={title} id={id} buttons={buttons} />
          {/* <div className="categories">
            <div className="d-flex justify-content-between">
              <button className="btn fw-bold fs-3" onClick={this.toggle}>
                {title}
              </button>
              {show && <button className="btn btn-dark" onClick={this.close}>done</button>}
            </div>
            {show &&
              <div className="categories-list">
                <div className="categories-list-inner categories-list-short">
                  {buttons}
                </div>
              </div>
            }
          </div> */}
        </div>
      </>
    )
  }
}
List.contextType = SearchContext


export default List