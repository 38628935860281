import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Spinner from 'react-bootstrap/Spinner'
import {Restricted, Public} from '../components/user/elements'
import {useSelector, useDispatch } from 'react-redux'
import {Link } from "@reach/router"


const ContactPage = (props) => (
  <Layout location={props.location} title="Contact">
    <SEO title="Contact" />
    <div className="container-fluid container-app" >
      <div className="row g-0">
        <div className="col">

          <Restricted>
            <Contact />
          </Restricted>

          <Public>
            <div>
              Unauthorized. try to <Link to={'/'}>Login</Link>
            </div>
          </Public>

        </div>
      </div>
    </div>
  </Layout>
)


const Contact = () => {

  const {user} = useSelector(state => ({...state.user }))
  const contact = useSelector(state => (state.app.contact ))

  const name = [user.last, user.first].join(' ').trim()
  const label = name ? name : user.username
  const dispatch = useDispatch()

  if( contact.sent ){
    return(
      <div className="container-fluid">
        <div className="row">
          <div className="col">
          <p>
            Thank you !
            </p>
            <button type="reset" className="btn btn-secondary" onClick={() => (dispatch({type:'app/contact-reset' , payload: {}}))}>Back</button>
          </div>
        </div>
      </div>
    )
  }
  return (

      <div className="container-fluid">
        <div className="row">
          <div className="col">

            <h2>Hi {label} !</h2>
            <p>
            We would love to hear about your services and how we can do more to assist you and promote your business. Your time is valuable and we appreacite that you are taking time out to send us your thought. Please fill out the message field, and we'll get back to you about your concern or comments shortly.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col"><ContactForm dispatch={dispatch} {...contact} /></div>
        </div>
      </div>

    )
  }



export class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: '',
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  submitHandler = ( e ) => {

    e.preventDefault()

    const {body } = this.state
    const {dispatch } = this.props

    dispatch({type:'app/contact-send' , payload: {body:body}})

  }

  resetHandler = ( e ) => {

    e.preventDefault()

    const {dispatch } = this.props

    this.setState({body:''})
    dispatch({type:'app/contact-reset' , payload: {}})

  }

  render() {


    const { body} = this.state
    const { sending, sent   } = this.props

    console.log( 'sending =' , sending, body )

    const showSend = !sending && body !== ''
    const showReset = sending || body !== '' || sent

    return (

      <form onSubmit={this.submitHandler } onReset={this.resetHandler } >

        <div className="mb-3">
          <label htmlFor="contactBody" className="form-label">Your Message</label>
          <textarea
            value={body}
            name="body"
            className="form-control"
            id="contactBody"
            aria-describedby="emailHelp"
            required
            onChange={this.handleInputChange}
            rows="4"
          />
        </div>

        { sending && <Spinner animation="border"></Spinner> }
        <button type="submit" className="btn btn-primary" disabled={!showSend}>Send</button>
        {' '}
        <button type="reset" className="btn btn-secondary" disabled={!showReset}>Reset</button>

      </form>

    );
  }

}

export default ContactPage
