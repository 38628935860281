import React from "react"
import SEO from "../components/seo"


const GuidelinesPage = (props) => (
  <main className="main-iframe" >
    <SEO title="Brand Guide" />
    <iframe className="iframe" src="https://www.almabrandguide.com/" width="100%" />
  </main>
)
export default GuidelinesPage
