import React from "react"
import DateFomat from '../date-format'
import Axios from "axios"
import Default from '../../images/webinar.png'
//import Play from '../../images/play.png'
//import Arrow from '../../images/arrow.png'
import { PlayFill } from 'react-bootstrap-icons';
import  './card.scss'

class CardWebinar extends React.Component {

  state = {
    thumb:false
  }

  youtu = ( link ) => {

    //type = 'youtube';
    const id = link.replace('https://youtu.be/' , '').split('/')[0];

    /*
    https://img.youtube.com/vi/<insert-youtube-video-id-here>/0.jpg
    https://img.youtube.com/vi/<insert-youtube-video-id-here>/1.jpg
    https://img.youtube.com/vi/<insert-youtube-video-id-here>/2.jpg
    https://img.youtube.com/vi/<insert-youtube-video-id-here>/3.jpg
    */

    const img = 'https://img.youtube.com/vi/' + id +'/0.jpg'

    this.setState({thumb:img})
  }

  vimeo = ( link ) => {

    const id = link.replace('https://vimeo.com/' , '').split('/')[0];

    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    }

    Axios.get( `https://vimeo.com/api/v2/video/${id}.json`, options )

      .then(res => {
        const item = res.data[0]
        const img = item['thumbnail_medium']

        this.setState({'thumb' : img})

        //thumbnail_large: "https://i.vimeocdn.com/video/928441029_640.jpg"
        //thumbnail_medium: "https://i.vimeocdn.com/video/928441029_200x150.jpg"
        //thumbnail_small: "https://i.vimeocdn.com/video/928441029_100x75.jpg"


      })
      .catch(error => {
        if( error.response !== undefined ){
          //console.log(error.response.data)
        }
        if( error.request !== undefined ){
          //console.log('error in request')
          //console.log(error.request)
        }
      })
  }

  componentDidMount() {

    const { link, image  } = this.props

    if( image ){
      this.setState({thumb: image.url });
      return;
    }

    if( link.includes('youtube')   ){
      //type = 'youtube';
    }
    if( link.includes('youtu.be')  ){
      this.youtu( link )
    }

    if( link.includes('vimeo')   ){
      this.vimeo(link)
    }

  }

  render(){
    //console.log(this.props)
    const {thumb} = this.state
    const {title, link, created /* , changed */} = this.props

    const {withDate} = this.props
    //console.log( withDate)

    return (
      <div className="card card-webinar">
        <a href={link} target="blank">
          <div className="card-thumb" style={{backgroundImage:`url(${thumb ? thumb : Default})`}}>
            <div className="inner">
              <PlayFill size={40 } className="play" />
              {/* <img src={Play} alt="play" className="play" /> */}
            </div>
          </div>
        </a>

        <h5>{title}</h5>
        {withDate && <DateFomat timestamp={created} />}

      </div>
    )
  }
}



export default CardWebinar
