import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner'
import './register.scss'

class RegistrationForm extends React.Component {

  componentDidMount() {

    const { dispatch, loaded } = this.props

    if (!loaded) {
      dispatch({ type: 'registration/options', payload: {} })
    }

  }


  validate = (values) => {
    const errors = {};

    /* if (!values.username) {
      errors.username = 'Required';
    } */

    if (!values.email) {
      errors.email = 'Required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }


    if (!values.first_name) {
      errors.first_name = 'Required';
    }

    if (!values.last_name) {
      errors.last_name = 'Required';
    }

    if (!values.company) {
      errors.company = 'Required';
    }

    if (values.title === "false") {
      errors.title = 'Required';
    }

    if (values.country === "false") {
      errors.country = 'Required';
    }

    if (values.type === "false") {
      errors.type = 'Required';
    }

    if (values.type !== 'Alma') {
      if (!values.products || values.products.length < 1) {
        errors.products = 'Required';
      }
    }

    return errors;
  }


  convert = (values) => {

    const user = {}

    user.name = { value: values.email }
    user.mail = { value: values.email }
    user.field_company = { value: values.company }
    user.field_country_select = { value: values.country }
    user.field_first_name = { value: values.first_name }
    user.field_last_name = { value: values.last_name }
    user.field_products = values.products.map(p => ({ target_id: p }))
    user.field_registration_request_type = { value: values.type }
    user.field_title = { value: values.title }
    user.field_address = { value: values.address }
    user.field_city = { value: values.city }
    user.field_mobile = { value: values.cellphone }
    user.field_phone = { value: values.phone }
    user.field_province_or_state = { value: values.state }


    //: {value:'AF'},


    /* const data = {
      // =========== required
      name:{value:'aaa'},
      mail:{value:'aa@bb.com'},
      field_company:{value:'compnay name'},
      field_first_name:{value:'first'},
      field_last_name:{value:'last'},

      field_country_select: {value:'AF'},
      field_products: [
        {target_id:55},
        {target_id:135},
      ],
      field_registration_request_type: {value:'Alma'},
      field_title: {value:'Mr.'},

      // =========== optinal
    } */



    return user
  }

  submit = (values, { setSubmitting }) => {
    const { dispatch } = this.props
    //setSubmitting(false);

    //console.log(values)

    const data = this.convert(values)

    //console.log(data)

    //submit(values);

    dispatch({ type: 'registration/init', payload: data })
  }

  render() {

    const { options, submitting, messages } = this.props

    const msgs = messages.map((m, i) => (
      <div className="alert alert-danger" key={i} role="alert">{m}</div>
    ))

    const initialValues = {
      //username: '',
      email: '',
      city: '',
      state: '',
      type: '',
      title: '',
      first_name: '',
      last_name: '',
      company: '',
      phone: '',
      cellphone: '',
      address: '',
      country: '',
      products: [],
    }

    return (
      <div>

        <Formik
          initialValues={initialValues}
          validate={this.validate}
          onSubmit={this.submit}

        >
          {({
            isSubmitting,
            isValid,
            values,
            /* and other goodies */
            //...rest
          }) => (
            <Form className="register-form">
              <div className="container-fluid">

                <div className="row">
                  <div className="col">
                    {msgs}
                  </div>
                </div>

                <div className="row">

                  <div className="col-md-6">

                    {/* <InputField name="username" title="Username" required={true}>
                  <div className="form-text">Spaces are allowed; punctuation is not allowed except for periods, hyphens, apostrophes, and underscores.</div>
                </InputField> */}

                    <InputField name="email" title="Email address" type="email" required={true}>
                      <div className="form-text">A valid e-mail address. All e-mails from the system will be sent to this address. The e-mail address is not made public and will only be used if you wish to receive a new password or wish to receive certain news or notifications by e-mail.</div>
                    </InputField>

                    <InputField name="city" title="City" />

                    <InputField name="state" title="Province/State" />

                    <SelectField title="I'm a" name="type" required={true} options={options.types} />

                    <SelectField name="title" title="Title" required={true} options={options.titles} />

                  </div>
                  <div className="col-md-6">

                    <InputField title="First Name" name="first_name" required={true} />

                    <InputField title="Last Name" name="last_name" required={true} />

                    <InputField title="Company" name="company" required={true} />

                    <InputField title="Phone" name="phone" />

                    <InputField title="Cell Phone" name="cellphone" />

                    <InputField title="Address" name="address" />

                    <SelectField title="Country" name="country" required={true} options={options.countries} />
                    <CountryMsg value={values.country} />

                  </div>
                </div>

                <div className="row">

                  <div className="col products">
                    {values.type !== 'Alma' &&
                      <CheckboxedField title="Equipment owned" name="products" required={true} options={options.products} />
                    }
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <button type="submit" className="btn btn-primary" disabled={!isValid} /* disabled={isSubmitting} */>
                      {submitting && <Spinner animation="border" />}
                      Create new account

                    </button>
                  </div>
                </div>

              </div>
            </Form>
          )}
        </Formik>
      </div>
    )

  }
}

const CountryMsg = ({ value}) => {
  //console.log(value)
  if (value === 'CA' || value === 'US') {
    return (
      <div><p>For North America local Partners Zone: <a href="https://www.almaacademyonline.com/">click here</a></p></div>
    )
  } else {
    return false
  }

}

const InputField = ({ name, title, type = 'text', required = false, children }) => {

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-3">
          <label htmlFor={name + "Input"} className="form-label">{title} {required ? ' *' : ''}</label>
        </div>
        <div className="col-9">

          <Field type={type} name={name} className="form-control" id={name + "Input"} required={required} />
          <ErrorMessage name={name} component="div" className="alert alert-danger" />
          {children}
        </div>
      </div>
    </div>
  )
}



const SelectField = ({ title, required = false, name, options, children }) => {

  const items = options.map(item => (
    <option value={item.id} key={item.id}>{item.value}</option>
  ))
  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-3">
          <label htmlFor={name + "Input"} className="form-label">{title} {required ? ' *' : ''}</label>
        </div>
        <div className="col-9">
          <Field as="select" name={name} className="form-select" required={required}>
            <option value={false}>- Select a value -</option>
            {items}
          </Field>
          <ErrorMessage name={name} component="div" className="alert alert-danger" />

        </div>
      </div>
    </div>
  )
}



const CheckboxedField = ({ title, required = false, name, options }) => {

  const items = options.map(item => (

    <div className="form-check col-sm-3" key={item.id}>
      <label className="form-check-label" htmlFor={"Check" + item.id}>
        <Field type="checkbox" name={name} value={item.id} className="form-check-input" id={"Check" + item.id} />
        {item.value}
      </label>

    </div>
  ))

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col">
          <label className="form-label">{title} {required ? ' *' : ''}</label>
        </div>
      </div>

      <div className="row mb-3">
        {items}
      </div>

      <div className="row mb-3">
        <div className="col">
          <ErrorMessage name={name} component="div" className="alert alert-danger" />
        </div>
      </div>

    </div>
  )
}



export default connect(state => ({ ...state.registration }))(RegistrationForm);
