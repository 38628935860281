import Axios from "axios"
import {endPoint} from "../config"

const masquerade = storeAPI => next => action => {

  //console.log('befor' , action )

  //console.log('action type:', action.type, action)

  if( action.type === 'masquerade/masquerade-sccuess'){
    // add the old user
    const state = storeAPI.getState()
    action.payload.old_user = {
      user: state.user.user,
      jwt: state.user.jwt
    }
  }

  if( action.type === 'masquerade/unmasquerade'){
    // add the old user
    const state = storeAPI.getState()
    console.log(state)
    console.log(action)
    action.payload.old_user = state.masquerade.old_user
  }

  next(action)
  //console.log('after' , action )
  //return

  if( action.type === 'masquerade/masquerade-sccuess'){
    //const state = storeAPI.getState()
    storeAPI.dispatch({
      type:'app/load'
    })

  }

  if( action.type === 'masquerade/unmasquerade'){
    //const state = storeAPI.getState()
    storeAPI.dispatch({
      type:'app/load'
    })

  }

  if( action.type === 'masquerade/load'){
    //console.log('load')
    const state = storeAPI.getState()
    loadData(state.user.jwt, storeAPI.dispatch )
  }


  if( action.type === 'masquerade/masquerade'){
    //console.log('masquerade')
    const state = storeAPI.getState()
    loadMasqueradeData(action.id, state.user.jwt, storeAPI.dispatch )
  }


}


const loadData = (jwt, dispatch) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.get(
    endPoint + `/api/kgmedia-masquerade?_format=json`,
    { headers: headers }
  )
  .then(res => {

    const users = res.data.users

    dispatch({
      type:'masquerade/load-sccuess',
      payload:{
        users: users,
      },
    })

  })
  .catch(error => {
    //console.log(error)
    console.log(error.response)

    //return
    //console.log(error.request)

    if( error.response !== undefined ){
      console.log(error.response.data)

      if( error.response.data.message === "Expired token" ){
        dispatch({
          type:'user/expired-token',
          payload:{
            messages: "Expired token"
          }
        })


      }

      if( error.response.data.message === "Signature verification failed" ){

        dispatch({
          type:'user/SIGNATURE_VARIFICATION_FAILED',
          payload:{
            messages: "Signature verification failed"
          }
        })

        //logout( () => { navigate('/') } )
      }


      dispatch({
        type:'masquerade/load-error',
        payload:{
          error: error.response.data.message
        }
      })


    }

    if( error.request !== undefined ){
      console.log( error.request )
      dispatch({
        type:'masquerade/load-error',
        payload:{
          error: 'see console'
        }
      })
    }

  })


}

const loadMasqueradeData = (id,  jwt, dispatch) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.post(
    endPoint + `/api/kgmedia-masquerade?_format=json`,
    {id:id},
    { headers: headers }
  )
  .then(res => {

    console.log( res.data )

    dispatch({
      type:'masquerade/masquerade-sccuess',
      payload:{
        new_user: res.data,

      }
    })

  })
  .catch(error => {
    //console.log(error)
    console.log(error.response)


    if( error.response !== undefined ){
      console.log(error.response.data)

      if( error.response.data.message === "Expired token" ){
        dispatch({
          type:'user/expired-token',
          payload:{
            messages: "Expired token"
          }
        })

        //logout( () => { navigate('/') } )
      }

      if( error.response.data.message === "Signature verification failed" ){

        dispatch({
          type:'user/SIGNATURE_VARIFICATION_FAILED',
          payload:{
            messages: "Signature verification failed"
          }
        })

        //logout( () => { navigate('/') } )
      }


      dispatch({
        type:'masquerade/masquerade-error',
        payload:{
          error: error.response.data.message
        }
      })


    }

    if( error.request !== undefined ){
      console.log( error.request )

      dispatch({
        type:'masquerade/masquerade-error',
        payload:{
          error: 'see console'
        }
      })

    }

  })


}

export {masquerade}