import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Card from '../components/webinar/card'
import { useSelector } from 'react-redux'
//import "../components/webinar/block.scss"

const SearchPage = (props) => {

  const webinars = useSelector(state => state.app.webinars )

  //console.log( webinars)
  return (

    <Layout location={props.location} title="Webinars">
      <SEO title="Webinars" />
      <Webinars webinars={webinars} />
    </Layout>

  )
}


const Webinars = (props ) => {

  const {webinars} = props
  //console.log( notifications )

  const cards = webinars.map(item => {

    return (
      <div className="col-2" key={item.id}>
        <Card {...item} withDate={true} />
      </div>
    )
  })


  return (
    <div className="container-fluid container-app">
      <div className="row row-webinars">
        {cards}
      </div>
    </div>
  )
}



export default SearchPage
