import React, { Component, useState } from "react"
import ExtentionIcon from "../extentions/extention2"
import { LangsNoLinks } from "../languages"
import { CaretUp, CaretUpFill, CaretDown, CaretDownFill, ThreeDots } from "react-bootstrap-icons"
import { Link } from "@reach/router"
import ToolTip from "../tooltip/tooltip"
import './files-table.scss'
import StarButton from "../favorites/star"
import DateFomat, {timeConverter} from "../date-format"
import { useSelector } from 'react-redux'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const FilesTable = (props) => {

  const roles = useSelector(state => state.user.user.roles)
  const isAdmin = roles.includes('administrator')

  if (props.files.length === 0) return <div>No items yet.</div>;

  return (
    <FilesTableWrapper {...props} isAdmin={isAdmin} />
  )
}


class FilesTableWrapper extends Component {

  render() {

    const { files, history = false, search = false, isAdmin } = this.props
    //const {  modal } = this.state
    //const {  show, file } = modal

    //console.log(files)


    const definitions = {
      cols: [

        {
          key: 'favorites',
          label: '',
          view: (item) => (<StarButton favorite={item.favorite} id={item.id} />),
          headerProps: { style: { width: "30px" }, 'aria-label': "stared" }
        },

        {
          key: 'extention',
          label: 'Type',
          //sort:((a,b) => {return a.file.filemime > b.file.filemime ? 1 : -1}),
          view: (item) => (<ExtentionIcon file={item.file} />),
          headerProps: { style: { width: "60px" } }
        },
        {
          key: 'label',
          label: 'Name',
          sort: ((a, b) => { return a.title > b.title ? 1 : -1 }),
          view: (item) => (<Label item={item} />),
        },
        {
          key: 'languages',
          label: 'Languages',
          //sort:((a,b) => ( true )),
          view: (item) => (<LangsNoLinks item={item} />),
        },

        {
          key: 'modified',
          label: 'Modified',
          sort: ((a, b) => { return a.changed > b.changed ? 1 : -1 }),
          default: true,
          view: (item) => (<DateFomat timestamp={item.changed} />),
        },

      ]
    }

    if (history) {
      definitions.cols.push({
        key: 'downloaded',
        label: 'Downloaded',
        //sort:((a,b) => {return a.downloaded > b.downloaded ? 1 : -1}),
        view: (item) => (<DateFomat timestamp={item.downloaded} />),
      })
    }


    if (search) {
      definitions.cols.push({
        key: 'product',
        label: 'Product',
        //sort:((a,b) => {return a.downloaded > b.downloaded ? 1 : -1}),
        view: (item) => (<Product item={item} />),
      })
    }


    if (isAdmin && !history) {

      definitions.cols.push({
        key: 'views',
        label: 'Views',
        sort: ((a, b) => { return parseInt(a.stats.views) > parseInt(b.stats.views) ? 1 : -1 }),
        view: (item) => (<div>{item && item.stats ? item.stats.views : ''}</div>),
      })

      definitions.cols.push({
        key: 'downloads',
        label: 'Downloads',
        sort: ((a, b) => { return parseInt(a.stats.downloads) > parseInt(b.stats.downloads) ? 1 : -1 }),
        view: (item) => (<div>{item && item.stats ? item.stats.downloads : ''}</div>),
      })


    }

    return (
      <div className="row g-0">
        <div className="col list-files">
          <TableGroup items={files} definitions={definitions} />
        </div>
      </div>
    )
  }

}

class TableGroup extends React.Component {

  state = {
    itemsNumberToShow: 20,
    end: false,
    sort: {
      col: false,
      dir: 'desc' // or 'asc'
    },
    sorts: []
  }

  constructor(props) {
    super(props);
    this.loadingRef = React.createRef();
  }

  componentDidMount() {

    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(
      this.handleObserver,
      options
    );

    this.observer.observe(this.loadingRef.current);

  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  handleObserver = (entities, observer) => {

    const { itemsNumberToShow, end } = this.state;
    const { items } = this.props;

    if (!end) {

      const newNumber = itemsNumberToShow + 10
      const newEnd = newNumber >= items.length;

      this.setState({ itemsNumberToShow: newNumber, end: newEnd })

    }

  }


  render() {

    const { itemsNumberToShow, end } = this.state
    const { items, definitions } = this.props
    const itemsCurrent = items.slice(0, itemsNumberToShow)


    return (
      <div>
        <TableSort items={itemsCurrent} key={Math.random} definitions={definitions} />
        {!end && <div ref={this.loadingRef}>loading...</div>}

{ end && <DownloadReport items={items} />}
      </div>
    )
  }
}


class TableSort extends React.Component {

  state = {
    sort: {
      col: 'modified',
      dir: 'desc' // or 'asc'
    }
  }

  sort = (e) => {

    const id = e.currentTarget.id
    const parts = id.split(':')

    this.setState({
      sort: {
        col: parts[0],
        dir: parts[1],
      }
    })
  }

  render() {

    const { sort } = this.state
    const { col, dir } = sort
    const { items, definitions } = this.props

    const itemsCurrent = items

    if (col) {

      switch (col) {
        case 'modified':
          itemsCurrent.sort(((a, b) => { return a.changed > b.changed ? 1 : -1 }))
          break;
        case 'label':
          itemsCurrent.sort((a, b) => { return a.title > b.title ? 1 : -1 })
          break;
        case 'views':
          itemsCurrent.sort((a, b) => { return parseInt(a.stats.views) > parseInt(b.stats.views) ? 1 : -1 })
          break;
        case 'downloads':
          itemsCurrent.sort((a, b) => { return parseInt(a.stats.downloads) > parseInt(b.stats.downloads) ? 1 : -1 })
          break;
        case 'extention':
          itemsCurrent.sort((a, b) => { return a.file.filemime > b.file.filemime ? 1 : -1 })
          break;

        default:
          // no sort
          break;
      }

      if (dir !== 'asc') {
        itemsCurrent.reverse()
      }

    }

    const k = dir + col + Math.random()

    return (
      <div>
        <Table
          items={itemsCurrent}
          key={k}
          sort={this.sort}
          definitions={definitions}
          current={col}
          dir={dir}
        />
      </div>
    )
  }
}



const Table = ({ items, sort, definitions, current, dir }) => {

  const [showAll/* , setShowAll */] = useState(false)

  const rows = items.map((item, index) => {

    return (

      <tr key={'D' + index} >
        {definitions.cols.map((c, i) => (
          <td key={i}>{c.view(item)}</td>
        ))}
      </tr>

    )
  })

  /* const toggleAll = () => {
    setShowAll(!showAll)
  } */

  return (
    <>
      <div className="show-mobile">
        <div className="d-flex justify-content-between px-2 pt-1 pb-0 m-0">
          <div>
            <SortButtonBasic label="Type" id={'extention'} sort={sort} dir={current === 'extention' ? dir : 'asc'} active={current === 'extention'} />
          </div>
          <div className="flex-grow-1">
            <SortButtonBasic label="Name" id={'label'} sort={sort} dir={current === 'label' ? dir : 'asc'} active={current === 'label'} />
          </div>
          {/* <div>
            <ToogleAllButton onClick={toggleAll} active={!showAll} />
          </div> */}
        </div>

        <div className="border-bottom border-1 mx-2"></div>
        <div className="table-files">
          {
            items.map((item, index) => (
              <FileCard key={'showAll' + showAll + 'i' + index} item={item} open={showAll} definitions={definitions} />
            ))
          }
        </div>
      </div>

      <div className="show-desktop">
        <table width="100%" className="table table-files">
          <thead>
            <tr className="">
              {definitions.cols.map(c => {

                const className = typeof c.sort == 'function' && current === c.key ? 'label' : '' //c.key === 'label' ? 'label' : ''

                if (typeof c.sort == 'function') {
                  return (
                    <th scope="col" className={className} {...c.headerProps} key={c.key}>{c.label}
                      <SortButton id={c.key} sort={sort} dir={current === c.key ? dir : 'asc'} active={current === c.key} />
                    </th>
                  )
                }

                return (
                  <th scope="col" key={c.key} {...c.headerProps}>{c.label}</th>
                )
              }
              )}


            </tr>

          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    </>
  )

}




const Label = ({ item }) => {

  //if (!item.image) return false

  //const { preview } = item.image
  const imgHover = item.image ? <div><img src={item.image.preview.url} alt="" height="340" width="420" style={{ border: "1px solid grey" }} /></div> : <div></div>
  return (
    <ToolTip image={imgHover}>
      <div style={{ width: "100%" }}>
        <Link to={item.to}>{item.label}</Link>
      </div>
    </ToolTip>
  )
}

const SortButton = ({ id, sort, dir, active }) => {

  //console.log('SortButton' , dir,  active)

  let left
  let right

  if (!active) {
    left = <CaretUp />
    right = <CaretDown />
  } else if (dir === 'asc') {
    left = <CaretUpFill />
    right = <CaretDown />
  } else {
    left = <CaretUp />
    right = <CaretDownFill />
  }

  return (
    <button onClick={sort} id={id + ':' + (dir === 'asc' ? 'desc' : 'asc')}>
      {left}
      {right}
    </button>
  )
}

const SortButtonBasic = ({ label, id, sort, dir, active }) => {

  const classes = active ? ' fw-bold text-decoration-underline color-purple' : ' color-grey65'

  return (
    <button className={"btn  p-1 mt-1 " + classes} onClick={sort} id={id + ':' + (dir === 'asc' ? 'desc' : 'asc')}>
      {label}
    </button>
  )
}

/* const ToogleAllButton = ({ active, onClick }) => {

  return (
    <button className="btn p-1 m-0" onClick={onClick}>
      {active && <><CaretUp /><CaretDownFill /></>}
      {!active && <><CaretUpFill /><CaretDown /></>}
    </button>
  )
} */

const Product = (props) => {


  const { item } = props
  const products = item.products.map(p => p.label)

  const full = products.join(', ')
  const short = products.slice(0, 2).join(', ')

  const length = products.length

  if (length <= 2) {
    return <div >{short}</div>
  } else {
    return <div title={full}>{short} ...</div>
  }

}

const FileCard = (props) => {

  const { item, /* open, */ definitions } = props

  //console.log(item.to)

  //const [show, setShow] = useState(open)

  const label = definitions.cols.find(f => f.key === 'label')
  const extention = definitions.cols.find(f => f.key === 'extention')
  const favorites = definitions.cols.find(f => f.key === 'favorites')
  const languages = definitions.cols.find(f => f.key === 'languages')
  const modified = definitions.cols.find(f => f.key === 'modified')
  const views = definitions.cols.find(f => f.key === 'views')
  const downloads = definitions.cols.find(f => f.key === 'downloads')
  const downloaded = definitions.cols.find(f => f.key === 'downloaded')
  const product = definitions.cols.find(f => f.key === 'product')

  //console.log(item)
  /* const toggle = () => {
    setShow(!show)
  } */

  const classes =/*  show ? ' bg-grey ' : */ ' '

  return (

    <div className={"d-flex justify-content-between ps-3 pe-2 py-3" + classes}>
      <div >{extention.view(item)}</div>
      <div className="flex-grow-1">
        <div className="text-start ms-2 fs-6">{label.view(item)}</div>
        {false && /* show && */
          <div className="">

            {product &&
              <div className="-d-flex -justify-content-between">
                <div>{product.view(item)}</div>
              </div>
            }

            <div className="d-flex justify-content-start align-items-baseline">
              <div className="me-2">{favorites.view(item)}</div>
              <div className="me-2 ">{modified.view(item)}</div>
              <div>{languages.view(item)}</div>
            </div>

            {(views || downloads) &&
              <div className="d-flex -justify-content-between p-1">
                {views && <div className="me-3">Views: <span>{item && item.stats ? item.stats.views : ''}</span> </div>}
                {downloads && <div>Downloads: <span>{item && item.stats ? item.stats.downloads : ''}</span></div>}
              </div>
            }

            {downloaded &&
              <div className="d-flex justify-content-between">
                downloaded on: {downloaded.view(item)}
              </div>
            }
          </div>
        }
      </div>
      <div className="align-self-center">

        <Link to={item.to} className="btn p-1 m-0 outline">
          <ThreeDots />
        </Link>
        {/* <button className="btn p-1 m-0 outline" onClick={toggle}>
          {!show && <ThreeDots />}
          {show && <ChevronUp />}
        </button> */}
      </div>
    </div>

  )
}

const DownloadReport = ({items}) => {


  const roles = useSelector(state => state.user.user.roles)
  const isAdmin = roles.includes('administrator')

  if (!isAdmin) return false
  
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
      const ws = XLSX.utils.aoa_to_sheet(csvData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(data, fileName + fileExtension);
  }


  

  const fileName = 'download'
  const csvData = items.map(item => {
    const a = []
    
    a.push(item.id)
    a.push(item.label)
    a.push(item?.file?.filename ? item?.file?.filename.split('.').pop().toLowerCase() : '')
    a.push(timeConverter(item.changed))
    a.push(item.products.map(p => p.label).join(', '))
    a.push(item.stats.downloads)
    a.push(item.stats.views)
    a.push( [item.language, ...item.additional_languages.map(e=>e.language)  ].filter((v, i, a) => a.indexOf(v) === i).join(' ')) // unique
    
    return a
  })

  csvData.unshift([
    'id', 
    'Name',
    'Type',
    'Changed',
    'Products',
    'Downloads',
    'Views',
    'Languages',

  ])

  return (
    <div className="export-to-excel" 
    // style={{
    //   border:"1px solid red" , 
    //   padding:"3rem",
    //   display:"flex",
    //   flexDirection: "row",
    //   justifyContent: "flex-end"
    //   }}
      >

      <button onClick={(e) => exportToCSV(csvData,fileName)}>Export to Excel</button>
      
    </div>
  )
}
export default FilesTable
