export const endPoint = 'https://cms-pz.almalasers.com'
//export const endPoint = 'http://alma-partners-cms.lndo.site:8000'

/**
 * you can run
 * REACT_APP_API_URL=https://cms-pz.almalasers.com npm start
 * https://alma-partners-v9.lndo.site
 */
//export  const endPoint = process.env.REACT_APP_API_URL || "http://alma-partners-v9.lndo.site:8080"



// todo:
// normalize files inside file