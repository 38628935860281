import React from "react"
import SEO from "../components/seo"
import PasswordSetForm from "../components/user/password-set"
//import Layout2 from "../components/layout/layout2"
import Layout from "../components/layout/layout"
import qs from "query-string"
//import ForgetPassword from "../components/user/forget"

//class IndexPage extends React.Component{
const IndexPage = (props) => {


  //render(){

    const { location } = props

    //console.log( location )

    const parmas = qs.parse( location.search )
    const ev = parmas.key
    const ev2 = JSON.parse( window.atob(ev) );
    const timeout = ev2.timeout;
    const now = Math.floor( Date.now() /1000 )

    console.log( ev2 )
    const p = {
      uid:        ev2.uid,
      timestamp:  ev2.timestamp,
      token:      ev2.token,
      expired:    ev2.timestamp + timeout < now
    }

    console.log( p )
    return(
      <Layout
        location={location}
        //side1={<></>}
        //side2={<ResetBlock />}
        className="reverse"
        title="Password Set"
        >
        <SEO title="Password Set" />
        <ResetBlock params={p}  />
      </Layout>
    )

  //}

    }


const ResetBlock = (props) => {

  return (
    <div className="password-set-block">
      <h1>Choose a password</h1>
      {/* <p>Check your email and type the verification code.</p> */}
      <PasswordSetForm {...props} />
    </div>
  )

}

export default IndexPage
