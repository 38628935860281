import React from 'react';
import { useSelector } from 'react-redux'



const selectUser = (state)  => {
  return {...state.user}
}

export const Restricted = ({children}) => {
  const user = useSelector(selectUser)

  if( user.isLoggedIn ){
    return <>{children}</>
  }

  return false;
}

export const Public = ({children}) => {

  const user = useSelector(selectUser)

  if( ! user.isLoggedIn ){
    return <>{children}</>
  }

  return false;
}

