const initialState = {
  email:'',
  token:'',
  password:'',

  loading: false,
  messages:[],


  set:{
    loading:false,
    messages:[],
  },
  change:{
    loading:false,
    messages:[],
  },
};


const Reducer =  (state = initialState, action) => {
  switch (action.type) {
    case 'recover/init':
      return { ...state, email: action.payload.email , loading:true, messages:[]  };
    case 'recover/init-success':
      return { ...state, loading:false, messages:[] };
    case 'recover/init-failure':
      return { ...state, loading:false, messages:[action.payload.message]  };

    case 'recover/reset-pass':
      return {
        ...state,
        token: action.payload.token,
        password:action.payload.password,
        loading:true,
        messages:[],
      };

    case 'recover/reset-pass-success':
      return { ...initialState };

    case 'recover/reset-pass-failure':
      return { ...state, loading:false, messages:[action.payload.message]  };



    case 'recover/set-pass':
      return { ...state, set:{ loading:true, messages:[] } };

    case 'recover/set-pass-succsses':
      return { ...state, set:{ loading:false, messages:[] } };

    case 'recover/set-pass-failure':
      return { ...state, set:{ loading:false, messages:[action.payload.message] } };



    case 'recover/change-pass':
      return { ...state, change:{ loading:true, messages:[] } };

    case 'recover/change-pass-success':
      return { ...state, change:{ loading:false, messages:[] } };

    case 'recover/change-pass-failure':
      return { ...state, change:{ loading:false, messages:[action.payload.message] } };



    default:
      return state;
  }
};

export default Reducer

