import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import RegistrationForm from "../components/user/register"


const RegisterPage = (props) => (


  <Layout location={props.location} title="Register page">
    <SEO title="Register" />

    <div className="container-xl container-app">

      <div className="row">
        <div className="col">
          <h2>Get all the materials you need to succeed!</h2>
          {/*
          <p>Signing up for an Alma Partner Zone account enables you to get all the latest marketing collateral, kits and instructional videos in one place. They’ll be at hand and easily obtainable. When you log in, the news items will be right there for you to check out. </p>
           */}
          <p>All accounts are for Alma product owners and distributors only. After you fill out the form below, it will be reviewed by the Alma Lasers. If approved, you will received email notification that your account is active and you can start exploring the site.  </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <RegistrationForm />
        </div>
      </div>
    </div>

  </Layout>
)

export default RegisterPage
