import Axios from "axios"
import {endPoint} from "../config"
import { messaging } from "../../services/init-fcm";


const notification = storeAPI => next => action => {

  next(action)



  if( action.type === 'notification/read'){
    const state = storeAPI.getState()
    markRead( action.payload.ids , state.user.jwt,  storeAPI.dispatch )
  }

  if( action.type === 'notification/remove'){
    const state = storeAPI.getState()
    remove( action.payload.id , state.user.jwt,  storeAPI.dispatch )
  }


  if( action.type === 'notification/back'){
    notifyBack(action.payload, storeAPI.dispatch )
  }


  if( action.type === 'notification/get-token'){
    // add the old user
    //const state = storeAPI.getState()
    if( messaging === null)
      return
      
    messaging.getToken({ vapidKey: "BAHmiQxrLCg_Gy1h9H5CD6k1j0xB36XgWzx11-muvlExwiH3zAPES0lC_OVaWIaZ0qdxiUowlEk_-eo-Kv2cY_4" }).then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        console.log( currentToken)

        storeAPI.dispatch({
          type:'notification/send-token',
          payload:{token:currentToken}
        })

        //navigator.serviceWorker.addEventListener("message", (message) => console.log(message));

      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });

  }

  //console.log('after' , action )
  //return

  if( action.type === 'notification/send-token'){
    const state = storeAPI.getState()
    sendToken(action.payload.token, state.user.jwt,  storeAPI.dispatch )
  }

}



const sendToken = (token,  jwt, dispatch) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  Axios.post(
    endPoint + `/api/kgmedia-firebase?_format=json`,
    {
      token:token ,
      "type": "web"
    },
    { headers: headers }
  )
  .then(res => {
    console.log( res.data )
  })
  .catch(error => {
    //console.log(error)
    console.log(error.response)


    if( error.response !== undefined ){
      console.log(error.response.data)

      if( error.response.data.message === "Expired token" ){
        dispatch({
          type:'user/expired-token',
          payload:{
            messages: "Expired token"
          }
        })

        //logout( () => { navigate('/') } )
      }

      if( error.response.data.message === "Signature verification failed" ){

        dispatch({
          type:'user/SIGNATURE_VARIFICATION_FAILED',
          payload:{
            messages: "Signature verification failed"
          }
        })

        //logout( () => { navigate('/') } )
      }


      /* dispatch({
        type:'masquerade/masquerade-error',
        payload:{
          error: error.response.data.message
        }
      }) */


    }

    if( error.request !== undefined ){
      console.log( error.request )

      /* dispatch({
        type:'masquerade/masquerade-error',
        payload:{
          error: 'see console'
        }
      }) */

    }

  })


}


const notifyBack = (payload, dispatch ) => {


  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  }

  Axios.post(
    endPoint + `/api/kgmedia-notification-stats?_format=json`,
    payload,
    { headers: headers }
  )
  .then(res => {
    console.log( res.data )
  })
  .catch(error => {
    console.log(error)
    console.log(error.response)

    if( error.response !== undefined ){
      console.log(error.response.data)
    }

    if( error.request !== undefined ){
      console.log( error.request )
    }

  })


}



const markRead = (ids,  jwt, dispatch) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  const payload = {
    operation:"read",
    ids:ids
  }

  Axios.post(
    endPoint + `/api/kgmedia-notifications-alert?_format=json`,
    payload,
    { headers: headers }
  )
  .then(res => {
    console.log( res.data )
  })
  .catch(error => {
    //console.log(error)
    console.log(error.response)

    if( error.response !== undefined ){
      console.log(error.response.data)
    }

    if( error.request !== undefined ){
      console.log( error.request )
    }

  })


}


const remove = (id,  jwt, dispatch) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  const payload = {
    operation:"delete",
    ids:[id]
  }

  Axios.post(
    endPoint + `/api/kgmedia-notifications-alert?_format=json`,
    payload,
    { headers: headers }
  )
  .then(res => {
    console.log( res.data )
  })
  .catch(error => {
    //console.log(error)
    console.log(error.response)

    if( error.response !== undefined ){
      console.log(error.response.data)
    }

    if( error.request !== undefined ){
      console.log( error.request )
    }

  })


}


export {notification}