import React, { Component } from "react"
import { connect } from 'react-redux'

class DownloadButton extends Component {

  state = {
    url: false,
    isLoading: false
  }

  open = (url) => {
    if (typeof window !== "undefined") {
      window.open(url);
    }
  }


  download = (item) => {

    const { download } = this.props

    if (!download) return

    download(item)

    if (item.uri === false) {
      alert('no file')
      return
    }

    this.props.dispatch({
      type: 'app/download',
      payload: {
        item: item,
        fid: item.fid,
        nid: this.props.nid,
        url: ''
      }
    })

  }

  render() {

    const { file, label = 'Download', downloads } = this.props
    //const { url, isLoading } = this.state

    const job = downloads.find(d => d.fid === file.id)

    let url = ''
    let isLoading = false
    if (job) {
      isLoading = job.loading
      url = job.laoded ? job.url : ''
    }


    if (isLoading) {
      return <div>loading...</div>
    }

    if (url) {
      return <button className="btn btn-download"  >{label}</button>
    }

    return (
      <button className="btn btn-download"  >{label}</button>
    )

  }
}

export default connect(
  (state) => ({ jwt: state.user.jwt, downloads: state.app.downloads })
)(DownloadButton)