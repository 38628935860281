import React from "react"
import { Link } from "@reach/router"
import Layout from "../components/layout/layout"
import Layout2 from "../components/layout/layout2"
import Logo from "../components/layout/logo"
import SEO from "../components/seo"
import LoginForm from "../components/user/login"
import { useSelector/* , useDispatch */ } from 'react-redux'
import Home from "../components/home/home"
import Spinner from 'react-bootstrap/Spinner'
import RegisterImg from '../images/register.png'

const IndexPage0 = (props) => {

  const isLoggedIn = useSelector(state => (state.user.isLoggedIn))

  if (isLoggedIn) {
    return <HomePage {...props} />
  } else {
    return <IndexPage {...props} />
  }

}

const IndexPage = (props) => {

  return (

    <Layout2
      location={props.location}
      side1={<Login />}
      side2={<RegisterBlock />}
      login={true}
    >
      <SEO title="Home" />
    </Layout2>

  )
}

const Login = () => {

  return (
    <div>
      <div className="login-block">
        <div className="login-logo">
          <Logo />
        </div>
        <div className="crem">PARTNER</div>
        <div className="white">ZONE</div>
        <h2 className="block-title">Log in to your account</h2>
        <LoginForm />
        {/* <div>
          <a href="https://cms-pz.almalasers.com/samllogin">Login with SalesForce</a>
        </div> */}
      </div>
    </div>
  )
}


const RegisterBlock = () => {

  return (

    <div className="register-block">
      <div>
        <img src={RegisterImg} alt="Register" />
      </div>

      <h5>New to Partners Zone ?</h5>
      <p>The Alma Partners Zone is dedicated to owners and <br />distributors of Alma Lasers products.</p>
      <p>For North America local Partners Zone: <a href="https://www.almaacademyonline.com/">click here</a></p>
      <div>
        <Link className="btn btn-primary btn-wide" to="/register">Register</Link>
      </div>
    </div>
  )
}

const HomePage = (props) => {

  const { isLoading } = useSelector(state => ({ ...state.app }))

  return (
    <Layout location={props.location} >
      <SEO title="Home" />
      <div className="container-fluid container-app" >
        {isLoading && <Spinner animation="border"></Spinner>}

        <div className="row g-0">
          <div className="col">
            {!isLoading && <Home />}
          </div>
        </div>

      </div>
    </Layout>
  )

}


export default IndexPage0
