import { navigate } from "@reach/router"
import Axios from "axios"
import {endPoint} from "../config"


const recovery = storeAPI => next => action => {

  //console.log('action type:', action.type)

  next(action)

  if( action.type === 'recover/init'){
    notify( storeAPI.dispatch, action.payload.email )
  }

  if( action.type === 'recover/init-success'){
    navigate('/password-reset')
  }

  if( action.type === 'recover/reset-pass'){
    replace( storeAPI.dispatch, action.payload.email, action.payload.token, action.payload.password )
  }

  if( action.type === 'recover/reset-pass-success'){
    navigate('/password-success')
  }


  if( action.type === 'recover/set-pass'){
    setPassword( storeAPI.dispatch, action.payload)
  }

  if( action.type === 'recover/set-pass-success'){
    navigate('/password-set-success')
  }


  if( action.type === 'recover/change-pass'){
    const state = storeAPI.getState()
    changePassword( state.user.jwt, storeAPI.dispatch, action.payload.password )
  }

  if( action.type === 'recover/change-pass-success'){
    window.location.href='/profile/?success'
  }


}


const notify = (dispatch , email ) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + jwt
  }


  const payload = {
    username_or_email: email,
    location: window.location.protocol + "//" + window.location.host
  }


  Axios.post(
    endPoint + `/api/kgmedia-forget-password?_format=json`,
    payload,
    { headers: headers }
  )
  .then(res => {
    console.log(res.data);
    dispatch({
      type:'recover/init-success' ,
      payload:{} ,
    })

  })
  .catch(error => {

    console.log(error )

    let message = ''

    if( error.response !== undefined ){
      console.log('response' , error.response)

      message = error.response.data.message

    }  else if( error.request !== undefined ){
      console.log('request', error.request )
    } else {
      console.log(error )
    }



    dispatch({
      type:'recover/init-failure' ,
      payload:{message:message} ,
    })
    dispatch({type:'error', payload:{error:error }})
  })

}


const replace = (dispatch , email, token ,password  ) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + jwt
  }


  const payload = {
    //username_or_email: email,
    email: email,
    token: token,
    newPassword: password
  }


  Axios.post(
    endPoint + `/api/kgmedia-reset-password?_format=json`,
    payload,
    { headers: headers }
  )
  .then(res => {
    console.log(res.data);
    dispatch({
      type:'recover/reset-pass-success' ,
      payload:{} ,
    })

  })
  .catch(error => {

    console.log(error )

    let message = ''

    if( error.response !== undefined ){
      console.log('response' , error.response)

      message = error.response.data.message

    } else if( error.request !== undefined ){
      console.log('request', error.request )
    } else {
      console.log(error )
    }

    dispatch({
      type:'recover/reset-pass-failure' ,
      payload:{message:message} ,
    })
    dispatch({type:'error', payload:{error:error }})
  })

}

const setPassword = (dispatch , payload  ) => {

  payload.newPassword = payload.password
  delete payload.password

  console.log( payload )

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  Axios.post(
    endPoint + `/api/kgmedia-setpassword?_format=json`,
    payload,
    { headers: headers }
  )
  .then(res => {
    //console.log(res.data);
    dispatch({
      type:'recover/set-pass-success' ,
      payload:{} ,
    })

  })
  .catch(error => {

    console.log(error )

    let message = ''

    if( error.response !== undefined ){
      console.log('response' , error.response)

      message = error.response.data.message

    } else if( error.request !== undefined ){
      console.log('request', error.request )
    } else {
      console.log(error )
    }

    dispatch({
      type:'recover/set-pass-failure' ,
      payload:{message:message} ,
    })
    dispatch({type:'error', payload:{error:error }})
  })

}

const changePassword = (jwt, dispatch ,  password  ) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + jwt
  }

  const payload = {
    newPassword: password
  }

  Axios.post(
    endPoint + `/api/kgmedia-change-password?_format=json`,
    payload,
    { headers: headers }
  )
  .then(res => {
    console.log(res.data);
    dispatch({
      type:'recover/change-pass-success' ,
      payload:{} ,
    })

  })
  .catch(error => {

    console.log(error )

    let message = ''

    if( error.response !== undefined ){
      console.log('response' , error.response)

      message = error.response.data.message

    } else if( error.request !== undefined ){
      console.log('request', error.request )
    } else {
      console.log(error )
    }

    dispatch({
      type:'recover/change-pass-failure' ,
      payload:{message:message} ,
    })
    dispatch({type:'error', payload:{error:error }})
  })

}


export {recovery}