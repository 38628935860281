import React from "react"
import { useSelector, useDispatch } from 'react-redux'
import { StarFill } from "react-bootstrap-icons"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const StarButtonWrapper = (props) => {

  const data = useSelector((state) => ({ ...state }))
  const dispatch = useDispatch()

  return (
    <StarButton {...props} user={data.user} app={data.app} dispatch={dispatch} />
  )
}


class StarButton extends React.Component {

  click = () => {
    const { favorite, id, dispatch } = this.props
    dispatch({ type: 'app/favorites-set', payload: { action: favorite ? 'remove' : 'insert', nid: id } })
  }

  tooltip = (props) => {
    return (
      <Tooltip  {...props} >Add to starred</Tooltip>
    )
  }

  render() {

    const { favorite } = this.props

    const icon = favorite ? <StarFill color="#442c5d" size={25} /> : <StarFill color="#d5d5d5" size={25} />

    return (
      <OverlayTrigger placement="top" overlay={this.tooltip} >
        {({ ref, ...triggerHandler }) => (
          <button className="btn-star" ref={ref} onClick={this.click}>{icon}</button>
        )}
      </OverlayTrigger>

    )
  }
}



export default StarButtonWrapper
