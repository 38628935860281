const initialState = {

  isLoading:false,
  loaded:false,

  users:[],
  /* search:{
    email:'',
    username:'',
    name:'',
    role:'',
  }, */

  old_user: false, // hold the original user
  new_user: false, // hold the new user
  //roles:[''], // who is allowed to see and use page

  error:'',
};


const Reducer =  (state = initialState, action) => {
  switch (action.type) {
    case 'masquerade/load':
      return { ...state , isLoading:true , loaded:false, error:'' };
    case 'masquerade/load-sccuess':
      return { ...state, ...action.payload, isLoading:false, loaded:true   };
    case 'masquerade/load-error':
      console.log( action )
      return { ...state, ...action.payload, isLoading:false, loaded:false };

    case 'masquerade/masquerade':
      return { ...state, error:'' };
    case 'masquerade/masquerade-sccuess':
      return { ...state, ...action.payload  };
    case 'masquerade/masquerade-error':
      return { ...state, ...action.payload };

    case 'masquerade/unmasquerade':
      return { ...state, old_user: false, new_user:false };

    /* case 'masquerade/search':
      console.log( action )
      return { ...state, search: action.payload }; */

    default:
      return state;
  }
};

export default Reducer