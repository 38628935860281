import React from "react"
import {SearchContext} from './context'
import { XCircleFill } from "react-bootstrap-icons"


class Tags extends React.Component{

  click = (e) => {

    //console.log( e.target.id)
    const dispatch = this.context.dispatch
    const id = e.currentTarget.id;

    const parts = id.split(":")

    switch (parts[0]) {
      case 'keywords':
        dispatch({type:'KEYWORDS' , data:'' })
        break;

      case 'products':
      case 'treatments':
      case 'categories':
      case 'langs':
      case 'extensions':
        dispatch({type:'REMOVETERM' , data:{id:parts[1] , vid:parts[0]} })
        break;

      default:
        break;
    }

  }

  render(){

    const { keywords } = this.context
    //console.log( keywords )

    const tags = []
    if( keywords !== ''){
      tags.push(<li key="keywords">{keywords} <button id="keywords" onClick={this.click} aria-label="remove"><XCircleFill /></button></li>)
    }

    let term
    ['products','treatments','categories', 'langs', 'extensions'].forEach(k => {
      const ids = this.context[k]
      const terms = this.context.options[k]

      ids.forEach(id => {
        term = terms.find(t => t.id === id )
        //console.log(term )
        tags.push(<li key={id}>{term.label} <button onClick={this.click} aria-label="remove" id={k+":"+id}><XCircleFill /></button></li>)
      });

    });

    return (

      <>
        <h3>I'm looking for</h3>
        <ul className="tags">
          {tags}
        </ul>

      </>

    )
  }
}
Tags.contextType = SearchContext


export default Tags