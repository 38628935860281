import React, { useState, useEffect } from "react";

const Disclaimer = (props) => {

  const [show, setShow] = useState(false);

  useEffect(() => {
    const start = sessionStorage.getItem('disclaimer');
    setShow(start === 'false' ? false : true )
  }, [])


  const hide = () => {
    sessionStorage.setItem('disclaimer',false)
    setShow(false);
  };

  if (!show) return false;

  return (
    <div className="disclaimer">
      <div className="disclaimer-container">
        <div className="disclaimer-text">
          The regulatory status and indications for use of this product may vary
          depending on the country or region.
          <br />
          Please consult with Alma professionals for more information on the
          specific indications and regulatory status of this product in your
          area.
        </div>
        <button className="" onClick={hide}>
          X
        </button>
      </div>
    </div>
  );
};

export default Disclaimer;
