import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { useSelector } from 'react-redux'
import ReloadButton from "../components/button/reload";
import MasqueradeButton from "../components/button/masquerade";
import PasswordChangeForm from "../components/user/password-change";

const ProfilePage = (props) => (
  <Layout location={props.location} title="Profile">
    <SEO title="Profile" />
    <div className="container-fluid container-app" >
      <div className="row g-0">
        <div className="col">
          <Profile location={props.location} />
        </div>
      </div>
    </div>
  </Layout>
)


const Profile = (props) => {

  //console.log( props )
  //const [open, setOpen] = useState(false);

  const { user } = useSelector(state => ({ ...state.user }))

  if (!user) return false

  const { location } = props
  const { search } = location
  const success = search === '?success'


  // if success then show different message
  if (success) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="mb-2">Password changed successfully</div>
            <a href="/profile" className="btn btn-primary btn-wide" >to Profile</a>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid">


      <div className="row">
        <div className="col">
          <Admin />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ul>
            <li>Name: {user.last + ' ' + user.first}</li>
            <li>Email: {user.email}</li>
            {/* <li>Id: {user.uid}</li> */}
            {/* <li>Username: {user.username}</li> */}
            {/* <li>Roles: {user.roles ? user.roles.join(', ') : '-'}</li> */}
          </ul>
        </div>
      </div>


      <div className="row">
        <div className="col-md-6">

          <fieldset className="change-password">
            <legend>Change password</legend>

            <div className="password-change-block">
              <PasswordChangeForm />
            </div>
          </fieldset>


        </div>
      </div>


    </div>

  )
}



const Admin = () => {

  return (
    <div className="admin-panel">
      <ReloadButton />
      <MasqueradeButton />
    </div>
  )
}
export default ProfilePage