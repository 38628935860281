import React from "react"
import Layout from "../components/layout/layout"
import FilesTable from "../components/list/files-table"
import Spinner from 'react-bootstrap/Spinner'
import Categories from "../components/category/categories"
import SubCategories from "../components/category/sub-categories"
import { getParams, toUrlParams, filterFiles } from "../services/location"
import SEO from "../components/seo"
import { useSelector,/*  useDispatch , */ connect } from 'react-redux'
import FileDetails from '../components/file/details'
import { Link } from "@reach/router"

const Files = (props) => {

  const { location } = props


  return (

    <Layout location={location} >
      <SEO title="Files" />
      <div className="container-fluid container-files g-0" >

        <AppSpinner />

        <div className="row g-0">
          <div className="col">
            <TheFiles location={location} />
          </div>
        </div>

      </div>
    </Layout>

  )

}

class ListenerD extends React.Component {

  componentDidMount() {
    //console.log('componentDidMount')
    const dispatch = this.props.dispatch;
    dispatch({ type: 'stats/view', payload: { url: this.props.location.href } })
  }

  render() {
    return false
  }
}

const ListenerD2 = connect()(ListenerD)


const TheFiles = (props) => {

  const { location } = props


  const data = useSelector((state => ({ ...state.app })))
  //const dispatch = useDispatch()

  if (!data.files) return false;
  if (data.files.length < 1) return false;

  //console.log( data.files )
  // create urls accoirdinmg to location
  const files = filterFiles(location.search, data.files)
    //.filter(f => f.favorite )
    .map(f => ({ ...f, to: '/files/?' + toUrlParams(location.search, { id: f.id }) }))

  //console.log( files )
  return (

    <>
      <div className="container-fluid container-header">

        <div className="row">
          <div className="col">
            <Categories key={location.search} location={location} />
          </div>
        </div>

      </div>


      <div className="container-app"  >

        <div className="row align-items-center">
          <div className="col-md" style={{ marginBottom: "20px" }} >
            <SubCategories location={location} key={'subCategories' + location.search} />

          </div>
          <div className="col-md">
            <CategoryVideos location={location} />
          </div>
        </div>


      </div>
      <ListenerD2 key={'0' + location.search} location={location} />

      <div className="container-app-2">
        <FilesTable files={files} location={location} key={location.search} />
      </div>
      <FileDetails location={location} key={'2' + location.search} />

    </>

  )
}

const CategoryVideos = (props) => {

  //location={location}

  //console.log( props )
  const { location } = props
  const { product/* , section, category  */ } = getParams(location.search)

  //console.log( product )

  const data = useSelector((state => ({ nodes: state.app.nodes })))


  const data2 = data.nodes
    .filter(node => node.type === 'al_content')
    .filter(node => node.product.length !== 0)


  const found = data2.find(node => {
    const pids = node.product.map(p => p.id)
    return pids.includes(product)
  })

  //console.log( data2 )
  //console.log( found )

  if (!found) return false


  return (
    <div className="videos">
      <Link to={"/videos/" + found.id}>Watch Videos</Link>
    </div>
  )
}


const AppSpinner = () => {
  const isLoading = useSelector((state => (state.user.isLoading)))
  if (!isLoading) return false;
  return (
    <Spinner animation="border"></Spinner>
  )
}



export default Files