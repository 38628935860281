const v = 'alma-v1.001'
const old = [
  'alma-v1.000',
  'alma-v0.858',
  'alma-v0.857',
  'alma-v0.856',
  'alma-v0.855',
  'alma-v0.854',
  'alma-v0.853',
  'alma-v0.852',
  'alma-v0.851',
  'alma-v0.850',
  'alma-v0.84',
  'alma-v0.83',
  'alma-v0.80',
  'alma-v0.78',
  'alma-v0.74',
  'alma-v0.73',
  'alma-v0.72',
  'alma-v0.71',
  'alma-v0.7',
  'alma-v0.6',
  'alma-v0.5',
  'alma-v0.4',
]

old.forEach(v0 => {
  localStorage.removeItem(v0)
});


/* if (localStorage.getItem(v) === null) {
  localStorage.clear();
} */

export const loadState = () => {
  //console.log('loadState')
  try {
    const serializedState = localStorage.getItem(v);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};


export const saveState = (state) => {
  //console.log('saveState')
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(v, serializedState);
  } catch (error) {
    // ignore write errors
    console.log('saveState error', error)
  }
};