import React from "react"
import { useSelector } from 'react-redux'
import "./trade-shows.scss"

const TradesBlock = () => {

  const events = useSelector(state => (state.app.events))
  //const events =  data //.filter(i => i.type === 'event')

  const items = events.map((w, i) => {
    const className = i === 0 ? 'first' : (i === events.length - 1 ? 'last' : '')
    return (
      <CardTradeshow {...w} key={w.id} className={className} />
    )
  })

  return (
    <div className="block block-tradeshows">

      <div className="block-header">
        <h3>Upcoming congresses</h3>
      </div>

      <div className="block-body">
        {items}
      </div>

    </div>
  )
}


const CardTradeshow = (props) => {
  //
  const { label, image, place, link, when, both, className } = props;

  return (
    <>
      <div className="show-desktop">
        <a href={link} target="__blank" className={"card card-event " + className}>

          <div className="d-flex">
            <div className="flex-shrink-0" >
              <img src={image.url} alt={label} width="180" />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5>{label}</h5>
              <div className="ff-mont-light fs-17px">{when}</div>
              <div className="ff-mont-light fs-17px">{place}</div>
              {both && <div className="ff-mont-light fs-17px">booth: {both}</div>}
            </div>
          </div>
        </a>
      </div>

      <div className="show-mobile">
        <a href={link} target="__blank" className={"card card-event " + className}>

          <div className="">
            <div className="" >
              <img src={image.url} alt={label} width="180" />
            </div>
            <div className=" mt-3">
              <h5>{label}</h5>
              <div>{when}</div>
              <div>{place}</div>
              {both && <div>booth: {both}</div>}
            </div>
          </div>
        </a>
      </div>

    </>

  )

}


export default TradesBlock
