import React from "react"
import { connect, useDispatch } from 'react-redux'
import {onMessageListener} from "../../services/init-fcm"
import {Toast} from "react-bootstrap"



class Base extends React.Component{

  state = {
    messages:[],
  }

  async componentDidMount() {

    if (!("Notification" in window)) {
      //alert("This browser does not support desktop notification");
      console.log("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      //var notification = new Notification("Hi there ! this is textnotification");
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      const {dispatch } = this.props
      dispatch({type:'notification/get-token', payload:{}} );

      /* Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          var notification = new Notification("Hi there!");
        }
      }); */
    }



    onMessageListener().then(payload => {
      //setShow(true);
      //setNotification({title: payload.notification.title, body: payload.notification.body})
      console.log('onMessageListener', payload);

      const {dispatch } = this.props
      dispatch({type:'notification/back', payload:{ wpc_stats_id:payload.data.wpc_stats_id ,  operation:'message_received'}} );

      //const dispatch = this.props.

      //dispatch({type:'notification/add' , payload:payload });

      // NOTIFY BACK


      const {messages} = this.state

      messages.push( {...payload.data , show:true , id: payload.data.wpc_stats_id })

      this.setState({messages:messages})


    }).catch(err => console.log('failed: ', err));



  }

  close = ( id ) => {
    //this.setState({show:false})
    //console.log( id )

    const messages = this.state.messages.filter( m => ( m.id !== id ))

    this.setState({messages:messages})
  }
/*
  click = ( click_action ) => {

    const { dispatch  } = this.props

   // console.log( this.props )
    console.log( 'click_action',  click_action )
    //noify back

  } */

  render(){

    const {messages } = this.state;
    //console.log( messages )

    const toasts = messages.map( (m,i) => {
      return <NotificationToast {...m} key={i} close={this.close}   />
    })

    return <>
    {/* <ToastContainer></ToastContainer> */}
    <div style={{
          position: 'absolute',
          top: 20,
          right: 20,
          minWidth: 200
        }}>
    {toasts}
    </div>

    </>
  }
}


const NotificationToast = (props) => {

  //console.log( props )

  const { close} = props
  const { title , body, show , id, logo , image, click_action  } = props

  const dispatch = useDispatch()
  const click = () => {
    dispatch({type:'notification/back', payload:{ wpc_stats_id: id ,  operation: 'notification_click' } });

    window.open(click_action, '_blank').focus();
  }
  return (

    <Toast onClose={() => close(id)} show={show} delay={51000} autohide animation style={{
      /* position: 'absolute',
      top: 20,
      right: 20, */
      minWidth: 200
    }}>
      <Toast.Header>
        <img
          src={logo}
          className="rounded mr-2"
          alt=""
          width="64"
        />
        <strong className="mr-auto">{title} </strong>

        {/* <small>just now</small> */}
      </Toast.Header>
      <Toast.Body>
        <button className="btn" onClick={click} >
          {body}
          <img style={{maxWidth: 200}} src={image} alt={title} />
        </button>
      </Toast.Body>
    </Toast>
  )
}

export default connect()(Base)
