import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import IconList from "../components/extentions/list"

const IconsListPage = (props) => {

  const { location } = props
  return (
    <Layout location={location} title="Icon list">
      <SEO title="Icon list" />
      <IconList />
    </Layout>
  )
}




export default IconsListPage
