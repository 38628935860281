import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import FilesTable from "../components/list/files-table"
import FileDetails from '../components/file/details'
import { useDispatch, useSelector } from 'react-redux'
import { toUrlParams } from "../services/location"
import PageTags from "../components/search/page-tags"
import { getParams, buildSearchQuery } from "../components/search/functions"

const SearchPage = (props) => (

  <Layout location={props.location} title='Search results'>
    <SEO title="Search" />

    <div className="container-fluid container-files g-0" >
      <div className="row g-0">
        <div className="col">
          <TheFiles location={props.location} />
        </div>
      </div>
    </div>
  </Layout>

)


const TheFiles = (props) => {

  const { location } = props

  const data = useSelector(state => ({ ...state.app }))


  if (!data.files) return false;
  if (data.files.length < 1) return false;

  // find all params for url
  /*
    products
    treatments
    categories
    sections
    keywords
    year
    langs
    extentions
  */
  // filter files according

  const params = getParams(props.location.search)
  //console.log( params )

  const files = data.files
    //.filter(f => parseInt(f.id) % 19 === 0 ) // sections
    .filter(f => {
      if (!params.products) {
        return true
      } else {
        const inter = intersect(params.products, f.termsIds)
        return inter.length > 0
      }
    }) // products
    .filter(f => {
      if (!params.treatments) {
        return true
      } else {
        const inter = intersect(params.treatments, f.termsIds)
        return inter.length > 0
      }
    }) // treatments
    .filter(f => {
      if (!params.categories) {
        return true
      } else {
        const inter = intersect(params.categories, f.termsIds)
        return inter.length > 0
      }
    }) // categories
    .filter(f => {
      if (!params.sections) {
        return true
      } else {
        const inter = intersect(params.sections, f.termsIds)
        return inter.length > 0
      }
    }) // sections
    .filter(f => {
      //console.log( params.keywords )
      if (!params.keywords) {
        return true
      } else {


        const text = [
          f.title,
          f.desciption ? f.desciption : '',
          f.desciption2 ? f.desciption2 : '',
          f.products ? f.products.map(p => p.label).join(' ') : '',
          f.treatments ? f.treatments.map(p => p.label).join(' ') : '',
          f.categories ? f.categories.map(p => p.label).join(' ') : '',
          // add the product name, tretments names, category names, file names
        ]
          .concat(f.additional_languages.map(a => a.description))
          .join(' ');

        const words = text
          .trim()
          .toLowerCase()
          .replaceAll(' - ', ' ')
          .replaceAll('_', ' ')
          .replaceAll('-', ' ')
          .replaceAll('  ', ' ')
        //.split(' ')

        //const has = words.includes(params.keywords.toLowerCase() )
        //if( has )
        //console.log(words, params.keywords.toLowerCase() )

        return words.includes(params.keywords.toLowerCase());

        //const inter = intersect(params.keywords.toLowerCase().split(' '), words)
        //return inter.length > 0


        //return parseInt(f.id) % 11 === 0
        //const inter = intersect(params.sections, f.termsIds)
        //return inter.length > 0
      }
    }) // keywrods
    .filter(f => {

      if (!params.year) {
        return true
      } else {
        const t = new Date(f.created * 1000)
        const y = t.getFullYear()
        if (parseInt(f.id) < 100) {
          //console.log( y , parseInt(params.year)  )

        }
        return y === parseInt(params.year)
        //const inter = intersect(params.sections, f.termsIds)
        //return inter.length > 0
      }
    }) // langs
    .filter(f => {

      if (!params.langs) {
        return true
      } else {
        const langs = f.files.map(ff => ff.language)
        const inter = intersect(params.langs, langs)
        return inter.length > 0
      }
    })  // here filter extensions
    .filter(f => {

      if (!params.extensions) {
        return true
      } else {
        const extensions = f.files.map(ff => ff.file.filename.split('.').pop())
        //console.log(extensions , params.extensions)
        const inter = intersect(params.extensions, extensions)
        //console.log(inter)
        return inter.length > 0
      }
    })

    .map(f => ({ ...f, to: '/search/?' + toUrlParams(location.search, { id: f.id }) }))
  //also add product to table as cell

  //console.log( files.length, location.search)
  //console.log( files.map(f => f.to))
  //console.log('count=', files.length)


  return (


    <>
      <div className="container-fluid show-desktop" style={{ padding: "40px" }} >
        <PageTags location={props.location} />
        <Listner location={props.location} files={files} />
        <FilesTable files={files} location={props.location} search={true} />
        <FileDetails location={props.location} key={'2' + props.location.search} />
      </div>

      <div className="container-fluid show-mobile"  >
        <PageTags location={props.location} />
        <Listner location={props.location} files={files} />
        <FilesTable files={files} location={props.location} search={true} />
        <FileDetails location={props.location} key={'2' + props.location.search} />
      </div>
    </>
  )
}

const intersect = (a1, a2) => a1.filter(value => a2.includes(value));


const Listner = (props) => {

  const { files, location } = props

  const dispatch = useDispatch()
  const search = useSelector(state => state.stats.search)

  const params = getParams(location.search)
  params['resualt_count'] = files.length
  delete params.year
  delete params.sections

  const key = buildSearchQuery(params)

  if (key && !search.includes(key)) {
    dispatch({
      type: "stats/search",
      payload: {
        params: params,
        key: key,
        host: location.host
      }
    })
  }

  return false

}



export default SearchPage
