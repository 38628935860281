import React from "react"
import CatList from "./list"
import LangList from "./langs"
import ExtensionsList from "./extensions"
import { SearchContext } from "./context"
import { Search } from "react-bootstrap-icons"


class Controls extends React.Component {

  change = (e) => {
    const v = e.target.value
    this.context.dispatch({ type: 'KEYWORDS', data: v })
  }

  render() {


    const { keywords } = this.context

    return (
      <>


        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col">
              <h1 className="ff-mont-light">Advanced search</h1>
            </div>
          </div>

          <div className="show-desktop">
            <div className="row my-4">
              <div className="col-md">

                <div className="keywords">

                  <div>Keyword:</div>
                  <div className="w-100 me-3"><input onChange={this.change} name="keywords" value={keywords} type="search" /></div>
                  {/* <div className="help">Separate multiple keywords using a comma ( , )</div> */}


                </div>
              </div>
            </div>
          </div>

          <div className="show-mobile">
            <div className="row mb-4">
              <div className="col-md">

                <div className="keywords">

                  <div>
                    <Search />
                  </div>

                  <div className="w-100">
                    <input onChange={this.change} name="keywords" value={keywords} type="search" placeholder="Search by keywords" />
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="show-desktop">
            <div className="row">

              <div className="col-md-4">
                <CatList title="Products" id='products' />
              </div>

              <div className="col-md-4">
                <CatList title="Treatments" id='treatments' />
              </div>

              <div className="col-md-4">
                <CatList title="Categories" id='categories' />
              </div>


            </div>


            <div className="row my-4">

              <div className="col-md-4">
                <LangList title="Languages" id='langs' />
              </div>

              <div className="col-md-4">
                <ExtensionsList title="File types" id='extensions' />
              </div>

            </div>

          </div>

          <div className="show-mobile">
            <div className="row">

              <div className="col-md-4">
                <CatList title="Products" id='products' />
              </div>

              <div className="col-md-4">
                <CatList title="Treatments" id='treatments' />
              </div>

              <div className="col-md-4">
                <CatList title="Categories" id='categories' />
              </div>

              <div className="col-md-4">
                <LangList title="Languages" id='langs' />
              </div>

              <div className="col-md-4">
                <ExtensionsList title="File types" id='extensions' />
              </div>

            </div>

          </div>
          <div className="row">
            <div className="col">
            </div>
          </div>
        </div>
      </>
    )
  }
}
Controls.contextType = SearchContext



export default Controls