import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
//import Spinner from 'react-bootstrap/Spinner'
//import { Restricted, Public } from '../components/user/elements'
import { useSelector/* , useDispatch */ } from 'react-redux'
//import { Link } from "@reach/router"


const BasicPage = (props) => {

  const { nodeId } = props

  const nodes = useSelector(state => state.app.nodes)
    .filter(node => node.type === 'page')

  const node = nodes.find(n => n.id === nodeId)

  if (!node) return <div></div>


  const { title, body } = node

  return (
    <Layout location={props.location} title={title}>
      <SEO title={title} />
      <div className="container-fluid container-app" >
        <div className="row g-0">
          <div className="col">
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </div>
      </div>

    </Layout>
  )
}

/*
const Contact = () => {

  const {user} = useSelector(state => ({...state.user }))
  const contact = useSelector(state => (state.app.contact ))

  const name = [user.last, user.first].join(' ').trim()
  const label = name ? name : user.username
  const dispatch = useDispatch()

  if( contact.sent ){
    return(
      <div className="container-fluid">
        <div className="row">
          <div className="col">
          <p>
            Thank you !
            </p>
            <button type="reset" className="btn btn-secondary" onClick={() => (dispatch({type:'app/contact-reset' , payload: {}}))}>Back</button>
          </div>
        </div>
      </div>
    )
  }
  return (

      <div className="container-fluid">
        <div className="row">
          <div className="col">

            <h2>Hi {label} !</h2>
            <p>
            We would love to hear about your services and how we can do more to assist you and promote your business. Your time is valuable and we appreacite that you are taking time out to send us your thought. Please fill out the message field, and we'll get back to you about your concern or comments shortly.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col"><ContactForm dispatch={dispatch} {...contact} /></div>
        </div>
      </div>

    )
  }
 */


export default BasicPage
