import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReduxWrapper from './state/ReduxWrapper'
import CheckToken from './components/user/check-token'


import './styles/bootstrap.css';
import './styles/fonts.scss';
import './styles/global.scss';
import './styles/utilities.scss';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

ReactGA.initialize('UA-3585626-11');

 
const tagManagerArgs = {
    gtmId: 'GTM-W4XMWXV'
}
 
TagManager.initialize(tagManagerArgs)

if ("serviceWorker" in navigator) {
  //console.log('ok')
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Service worker registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

/* ReactDOM.render(
  <React.StrictMode>
    <ReduxWrapper>
    <App />
    </ReduxWrapper>
  </React.StrictMode>,
  document.getElementById('root')
); */

ReactDOM.render(

  <ReduxWrapper>
    <CheckToken>
      <App />
    </CheckToken>
  </ReduxWrapper>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
