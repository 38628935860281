import { Router/* , Link  */ } from "@reach/router"
import IndexPage from "./pages/index"
import NotFound, { NotFoundAnon } from "./pages/404"
import Register from "./pages/register"
import RegisterSuccess from "./pages/register-success"
import PasswordRecovery from "./pages/password-recovery"
import PasswordReset from "./pages/password-reset"
import PasswordSuccess from "./pages/password-success"
import PasswordSet from "./pages/password-set"
import PasswordSetSuccess from "./pages/password-set-success"
import Files from "./pages/files"
import Contact from "./pages/contact"
import Profile from "./pages/profile"
import Favorites from "./pages/favorites"
import History from "./pages/history"
import Masquerade from "./pages/masquerade"
import Notifications from "./pages/notifications"
import Search from "./pages/search"
import Webinars from "./pages/webinars"
import Videos from "./pages/videos"
import Basic from "./pages/basic"
import Guidelines from "./pages/guidelines";
import IconsListPage from "./pages/icons-list"
import LandingPage from "./pages/landing"
import { Restricted, Public } from './components/user/elements'
import NotificationsBase from './components/notification/base'

import Analytics from 'analytics'
import crazyEggPlugin from '@analytics/crazy-egg'

import { globalHistory } from '@reach/router';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init('352217531886505'/* , advancedMatching, options */);
ReactPixel.pageView(); // For tracking page view

globalHistory.listen(({ location }) => {
  ReactGA.pageview(location.pathname + location.search);
  ReactPixel.pageView();
});


/* eslint-disable */
const analytics = Analytics({
  app: 'almalasers-partners-zone',
  plugins: [
    // This will load crazy egg on to the page
    crazyEggPlugin({
      accountNumber: '00674816'
    })
  ]
})

//console.log(analytics)

const App = () => {
  return (
    <div className="App">

      <Restricted>
        <NotificationsBase />

        <Router>
          <IndexPage path="/" />
          <Files path="/files" />
          <Contact path="/contact" />
          <Profile path="/profile" />
          <Favorites path="/favorites" />
          <History path="/history" />
          <Masquerade path="/masquerade" />
          <Notifications path="/notifications" />
          <Search path="/search" />
          <Webinars path="/webinars" />
          <Videos path="/videos/:nodeId" />
          <Basic path="/node/:nodeId" />
          <Guidelines path="/guidelines" />
          <IconsListPage path="/icon-list" />
          <LandingPage path="/landing" />
          <NotFound default />
        </Router>
      </Restricted>

      <Public>
        <Router>
          <IndexPage path="/" />
          <Register path="/register" />
          <RegisterSuccess path="/register-success" />
          <PasswordRecovery path="/password-recovery" />
          <PasswordReset path="/password-reset" />
          <PasswordSuccess path="/password-success" />
          <PasswordSet path="/password-set" />
          <PasswordSetSuccess path="/password-set-success" />
          <LandingPage path="/landing" />
          <NotFoundAnon default />
        </Router>
      </Public>

    </div>
  );
}


export default App;
