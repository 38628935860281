import React, { useEffect, useState } from "react"
import { SearchBar } from "./header"
import /* Logo, */ { LogoMobile, LogoMobileSmall } from "./logo"
import { List } from "react-bootstrap-icons"
import { Modal/* , Button */ } from "react-bootstrap"
import Sidebar from "./sidebar"


import { globalHistory } from '@reach/router'

const NavbarMobile = (props) => {

  const { location } = props

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
  }


  useEffect(() => {
    globalHistory.listen(({ location, action }) => {
      setShow(false);
    })
  }, [])

  return (
    <>
      <div className="bg-purple pb-0 pt-4 px-2">
        <div className="d-flex justify-content-between align-items-end">
          <div><LogoMobile /></div>
          <div>
            <button className="btn p-1 text-white" onClick={handleShow}><List size="36" /></button>
          </div>
        </div>
        <div className="mt-2" >
          <SearchBar />
        </div>
      </div>


      <Modal show={show} onHide={handleClose} dialogClassName="w-100 m-0">
        <Modal.Header closeButton className="bg-purple ">
          <Modal.Title>
            <LogoMobileSmall />
          </Modal.Title>

        </Modal.Header>
        <Modal.Body className="p-0">

          <Sidebar location={location} />
        </Modal.Body>

      </Modal>
    </>
  )

}


/*
NavbarMobile.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object
} */

export default NavbarMobile
