const initialState = {

  files: [],
  terms: [],
  vocabularies: [],

  categories: [],
  products: [],

  notifications: [],
  webinars: [],
  nodes: [],
  events: [],
  media: [],

  favorite: [],
  history: [],

  corporateBlock: [],

  isLoading: false,
  loaded: false,

  downloads: [],  // add jon to array
  blocks: [],
  promoBlock: {},
  top_downloaded: [],
  top_updated: [],

  contact: {
    sending: false,
    sent: false,
  },

  searchOptions: {
    langs: [],
    extensions: [],

  },

  isSearchModalOpen: false,

  video: {
    loading: false,
    uri: false,
    url: false
  }

};


const Reducer = (state = initialState, action) => {
  //console.log(action)
  switch (action.type) {
    case 'app/load':
      return { ...state, isLoading: true, loaded: false };
    case 'app/load-sccuess':
      return { ...state, ...action.payload, isLoading: false, loaded: true };
    case 'app/load-error':
      return { ...state };
    case 'app/favorites':
      return { ...state };
    case 'app/favorites-success':
      //console.log( action.payload )
      return {
        ...state, files: state.files.map(f => {
          return { ...f, favorite: action.payload.includes(f.id) }
        })
      };
    case 'app/favorites-error':
      return { ...state };
    case 'app/favorites-set':
      return { ...state };
    case 'app/favorites-set-success':
      return {
        ...state, files: state.files.map(f => {
          return f.id === action.payload.id ? { ...f, favorite: action.payload.value } : { ...f }

        })
      };
    case 'app/stats-success':

      return {
        ...state, files: state.files.map(f => {
          const stats = action.payload[f.id] ? action.payload[f.id] : { views: 0, downloads: 0 }
          return { ...f, stats: stats }

        })
      };


    case 'user/expired-token':
      return { ...initialState }
    case 'masquerade/masquerade-sccuess':
      return { ...initialState }
    case 'masquerade/unmasquerade':
      return { ...initialState }
    case 'app/history-success':
      return { ...state, history: action.payload }
    case 'app/download':
      return { ...state, downloads: [{ ...action.payload, loaded: false, loading: true, url: '' }, ...state.downloads] }
    case 'app/download-success':
      return { ...state, downloads: state.downloads.filter(d => d.fid !== action.payload.fid) }
    case 'app/history':
      return { ...state, history: [...state.history, action.payload] }

    case 'app/top-downloaded-success':
      return { ...state, top_downloaded: action.payload }

    case 'app/top-updated-success':
      return { ...state, top_updated: action.payload }

    case 'app/contact-send':
      return { ...state, contact: { sending: true, sent: false } }
    case 'app/contact-send-success':
      return { ...state, contact: { sending: false, sent: true } }
    case 'app/contact-reset':
      return { ...state, contact: { sending: false, sent: false } }

    case 'notification/read':
      return {
        ...state, notifications: state.notifications.map(n => {
          return action.payload.ids.includes(n.is_read_id) ? { ...n, is_read: "1" } : n

        })
      }

    case 'notification/remove':
      return {
        ...state, notifications: state.notifications.filter(n => (
          action.payload.id !== n.is_read_id
        ))
      }

    case 'search-modal/open':
      //console.log('open');
      return { ...state, isSearchModalOpen: true }

    case 'search-modal/close':
      //console.log('close');
      return { ...state, isSearchModalOpen: false }

    case 'video/load':
      //console.log('video/load', action );
      return { ...state, video: { loading: true, uri: action.payload.uri, url: false } }

    case 'video/load-success':
      //console.log('close');
      return { ...state, video: { ...state.video, loading: false, url: action.payload.url } }

    case 'video/reset':
      //console.log('close');
      return { ...state, video: { loading: false, uri: false, url: false } }

    default:
      return state;
  }
};

export default Reducer