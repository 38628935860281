import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import './tooltip.scss'
/*
export default function Hover(props) {

  const {children, image } = props

  const renderTooltip = props => {

    return (
      <Tooltip {...props} className="tooltip-image">
        {image}
      </Tooltip>
    )
  };

  return (
    <div className="hoover-image">
      <OverlayTrigger placement='top-end' className="hoover-triger" overlay={renderTooltip}>
        {children}
      </OverlayTrigger>
    </div>
  );
} */


class Hover2 extends React.Component {



  renderTooltip = (props) => {
    const { image } = this.props
    return (
      <Tooltip {...props} className="tooltip-image">
        {image}
      </Tooltip>
    )
  };

  render(){

    const {children } = this.props

    return (
      <div className="hoover-image">
        <OverlayTrigger placement='top-end' className="hoover-triger" overlay={this.renderTooltip}>
          {children}
        </OverlayTrigger>
      </div>
    );

  }
}

export default Hover2
