import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import FilesTable from "../components/list/files-table"
import FileDetails from '../components/file/details'
import { useSelector } from 'react-redux'

const HistoryPage = (props) => {

  const { location } = props
  return (
    <Layout location={location} title="Download History">
      <SEO title="History" />
      <div className="container-fluid container-files g-0" >
        <div className="row g-0">
          <div className="col">
            <TheFiles location={location} />
            <FileDetails location={location} key={'2' + location.search} />
          </div>
        </div>
      </div>
    </Layout>
  )
}



const TheFiles = (props) => {

  const data = useSelector(state => ({ ...state.app }))

  if (!data.files) return false;

  if (data.files.length < 1) return false;

  const files2 = data.history.map(h => {

    const tf = data.files.find(f => parseInt(f.id) === parseInt(h.nid))
    return { ...tf, downloaded: h.timestamp, fid: h.fid }
  })

  const files = files2
    .map(f => ({ ...f, to: '/history/?id=' + f.id }))

  files.sort((a, b) => (a.downloaded < b.downloaded ? 1 : -1))

  //console.log(files)

  return (

    <div className="container-fluid container-app history-file-table"  >
      <FilesTable files={files} location={props.location} history={true} key={props.location.search} />
    </div>
  )
}


export default HistoryPage
