import React from "react"
//import DateFomat from '../date-format'
import Default from '../../images/webinar.png'
import { PlayFill } from 'react-bootstrap-icons';
import  './card.scss'

class CardVideo extends React.Component {

  render(){

    const {description, thumb} = this.props

    return (
      <div className="card card-video">
          <div className="card-thumb" style={{backgroundImage:`url(${thumb ? thumb.url : Default})`}}>
            <div className="inner">
              <PlayFill size={40 } className="play" />
            </div>
          </div>
          {/* <img src={thumb ? thumb.url : Default} alt="" width={230} /> */}
        <h5>{description}</h5>
      </div>
    )
  }
}



export default CardVideo
