import React from "react"
import PropTypes from "prop-types"
import Header/* , { SearchBar } */ from "./header"
import NavigationBar from "./navbar"
import NavigationBarMobile from "./navbar-mobile"

import SModal from "../search/modal"
import { connect } from "react-redux"
import { getOptions } from "../search/functions"
import Disclaimer from "./disclaimer"


const Layout = (props) => {

  const { title, children, location } = props
  /*
    const dispatch = useDispatch()

    useEffect(() => {
      console.log('use effect', location.href)
      //dispatch({type:'', payload:{}})
    }, [location])
   */
  return (
    <div className="main-container">

      <div className="show-desktop">
        <NavigationBar location={location} />
        {/* <Sidebar location={location} /> */}

        <main>
          <SearchModal />
          <Header title={title} location={location} />
          {children}
          <Disclaimer/>
        </main>
      </div>

      <div className="show-mobile">

        <NavigationBarMobile location={location} />

        <main>
          <SearchModal />
          <Header title={title} location={location} />
          {children}
          <Disclaimer/>
        </main>
      </div>

    </div>
  )

}

class SearchModal0 extends React.Component {

  render() {
    const options = getOptions(this.props)
    return (
      <SModal options={options} />
    )
  }
}

const SearchModal = connect((state) => ({ ...state.app }))(SearchModal0)



Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object
}

export default Layout
