import React, { useState } from "react"
//import { connect } from 'react-redux'
//import { Link } from "@reach/router"


const FirfoxMessage = () => {

  // check if firefox

  const isFirefox = (navigator.userAgent.indexOf('Firefox') !== -1);

  const show0 = localStorage.getItem('show-firefox-notice') !== 'false'

  const [show, setShow] = useState(true)


  const change = (e) => {
    localStorage.setItem('show-firefox-notice', !e.target.checked)
  }

  const hide = () => {
    setShow(false)
  }

  if (!isFirefox)
    return false

  if (!show0)
    return false;

  if (!show)
    return false;


  return (
    <div className="firefox-notice">
      <button type="button" className="btn btn-close" onClick={hide}></button>
      <p>We noticed you are using Firefox browser<br />
        For a better experience use Chrome, Edge, Safari or Opera</p>
      <p>
        <a href="https://support.mozilla.org/en-US/kb/mixed-content-blocking-firefox#w_unblock-mixed-content" target="__blank">Or change your Firefox settings to allow downloads</a>
      </p>
      <div className="form-check">

        <input type="checkbox" className="form-check-input" onChange={change} /* onClick={click} */ id="neverShowAgain" />
        <label className="form-check-label" htmlFor="neverShowAgain">Never show this message again</label>

      </div>

    </div>
  )
}


export default FirfoxMessage