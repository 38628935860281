import React from "react"
import { getParams, toUrlParams}  from "../../services/location"
import { connect } from 'react-redux'
import FileModal from './modal'
import { navigate} from "@reach/router"
//import './modal.scss'

class FileDetailsWrap extends React.Component{

  state = {
    show:false,
    id:false,
    file:false,
  }

  componentDidMount(){

    const { files, location  } = this.props

    const p = getParams(location.search)

    //console.log(p)

    const id =  p.id

    //const files = useSelector((state => (state.app.files)))

    const file = files.find(f => f.id === id )
    const show = ( id && file ) ? true : false

    this.setState({
      show:show,
      id:id,
      file:file,
    })
  }

  close = () => {
    this.setState({
      show:false,
      id:false,
      file:false,
    })

    const {  location } = this.props
    const s = toUrlParams(location.search , {id:''})
    navigate(location.pathname + '?' + s)
  }

  render(){

    const {  show, file } = this.state
    const {  location } = this.props

    return (
      <>
      { show && <FileModal show={show} close={this.close} file={file} location={location} /> }
      </>
    )
  }

}

export default connect(
  (state) => ({files: state.app.files })
)(FileDetailsWrap)
