import React, { useState } from "react"
import { Link } from "@reach/router"
import CategoryHeader from "../category/header"

import CopyIcon from "../../images/share-new.svg"
import NotificationsIcon from "../../images/notifications.png"
import HistoryIcon from "../../images/history.png"
import StarIcon from "../../images/star.png"
import SearchIcon from "../../images/search-icon.svg"
import { AdvancedButton } from "../search/modal"
import { useSelector } from "react-redux"
import Toast from 'react-bootstrap/Toast'
import WatchTutorialButton from '../button/tutorial'

const Header = (props) => {

  const { user } = useSelector(state => ({ ...state.user }))
  const { first } = user

  const { title = 'Hi ' + first + ', what are you looking for today?' } = props

  return (

    <div className="container-fluid container-page-header">


      <div className="show-desktop">
        <div className="container-page-header-grid">
          <div className="header-title"><CategoryHeader title={title} location={props.location} /></div>
          <div className="header-nav text-end" >
            <NabarRight />
          </div>

        </div>
      </div>


      <div className="show-mobile">
        <div className="header-title"><CategoryHeader title={title} location={props.location} /></div>
      </div>

    </div>
  )
}



const NabarRight = () => {

  const isLoggedIn = useSelector(state => (state.user.isLoggedIn))

  if (!isLoggedIn) return false

  return (
    <div>

      <SearchBar />

      <CopyToClipboardButton />
      <div className="d-inline-block ">

        <NotificationButton />


        <Link className="btn btn-navbar btn-navbar-mid" aria-label="favorites" title="Favorites" to={'/favorites'}>
          <img src={StarIcon} alt={'favorites'} />
        </Link>

        <Link className="btn btn-navbar btn-navbar-right" aria-label="history" title="History" to={'/history'}>
          <img src={HistoryIcon} alt={'history'} />
        </Link>
      </div>
      <WatchTutorialButton />

    </div>
  )
}

export const SearchBar = () => {

  const isLoggedIn = useSelector(state => (state.user.isLoggedIn))
  //console.log(isLoggedIn)

  if (!isLoggedIn) return <div className="py-2"></div>
  return (
    <div className="btn btn-navbar btn-navbar-search">
      <img src={SearchIcon} alt="Search"></img>
      <form action='/search/' style={{ padding: "0", margin: "0", display: "inline" }}>
        <input name="keywords" type="search" placeholder="Search" className="mx-2" style={{ paddingLeft: "10px" }} />
      </form>
      <AdvancedButton />
    </div>

  )
}

const CopyToClipboardButton = () => {

  const [copied, setCopied] = useState(false);

  const toast = copied ?
    <div style={{ position: "absolute", top: 100, right: "40px" }}>
      <Toast
        onClose={() => setCopied(false)}
        autohide={true}
      >
        <Toast.Header>
          <strong className="me-auto">Address Copied to clipboard</strong>
        </Toast.Header>
      </Toast>
    </div>
    : false

  return (
    <>
      {toast}
      <button className="btn btn-navbar btn-green mx-2" title="copy to clipboard" aria-label="copy to clipboard"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href)
          setCopied(true)
        }}
      >
        <img src={CopyIcon} alt={'Share'} />
      </button>
    </>
  )
}

const NotificationButton = () => {

  const notifications = useSelector(state => state.app.notifications)

  const unread = notifications.filter(n => n.is_read === '0')

  const showCircle = unread.length > 0

  return (
    <Link className="btn btn-navbar btn-navbar-left btn-notifications" title="Notifications" aria-label="notifications" to={'/notifications'} >
      {showCircle && <span className="notifictions-number" />}
      <img src={NotificationsIcon} alt={'notifications'} />
    </Link>
  )
}

export default Header
