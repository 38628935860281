
import { navigate } from "@reach/router"
import Axios from "axios"
import {endPoint} from "../config"


const registration = storeAPI => next => action => {

  //console.log('action type:', action.type)

  next(action)

  if( action.type === 'registration/options'){
    options( storeAPI.dispatch )
  }

  if( action.type === 'registration/init'){
    post(storeAPI.dispatch  , action.payload )
  }

  if( action.type === 'registration/init-success'){
    navigate('/register-success')
  }

}


const options = (dispatch  ) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  Axios.get(
    endPoint + `/api/kgmedia-register?_format=json`,
    { headers: headers }
  )
  .then(res => {
    console.log(res.data);
    dispatch({
      type:'registration/options-success' ,
      payload:{options: res.data} ,
    })

  })
  .catch(error => {

    console.log(error )

    let message = ''

    if( error.response !== undefined ){
      console.log('response' , error.response)

      message = error.response.data.message

    }  else if( error.request !== undefined ){
      console.log('request', error.request )
    } else {
      console.log(error )
    }



    dispatch({
      type:'registration/options-failure' ,
      payload:{message:message} ,
    })
    dispatch({type:'error', payload:{error:error }})
  })

}


const post = (dispatch , data  ) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + jwt
  }


  const payload = data


  Axios.post(
    endPoint + `/user/register?_format=json`,
    payload,
    { headers: headers }
  )
  .then(res => {
    console.log(res.data);
    dispatch({
      type:'registration/init-success' ,
      payload:{} ,
    })

  })
  .catch(error => {

    console.log(error )

    let message = ''

    if( error.response !== undefined ){
      console.log('response' , error.response)

      message = error.response.data.message

    } else if( error.request !== undefined ){
      console.log('request', error.request )
    } else {
      console.log(error )
    }

    dispatch({
      type:'registration/init-failure' ,
      payload:{message:message} ,
    })
    dispatch({type:'error', payload:{error:error }})
  })

}


export {registration}