import React from "react"
import { ReplyFill, StickiesFill} from "react-bootstrap-icons"
import "./share.scss"

class Share extends React.Component {

  state = {
    copy: false,
    url:''
  }

  copy = () => {
    navigator.clipboard.writeText(window.location.href)
    this.setState({
      copy:false
    })
  }

  share = () => {
    this.setState({
      copy:true,
      url:window.location.href
    })
  }

  render(){
    const {url, copy  } = this.state
    return (
      <>
      { ! copy && <button className="btn btn-light" onClick={this.share}>Share  <ReplyFill className="icon-flipped" size={20} /></button> }
      { copy &&
      <div className="share-copy">
        <input type="text"  value={url} readOnly />
        <button onClick={this.copy}><StickiesFill /> copy link</button>
      </div>
      }
      </>
    )
  }
}

export default Share